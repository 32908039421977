import React from "react"
import PropTypes from "prop-types"

import { Link } from "../utils/utils"

// Button
export const Button = ({
  variant,
  link,
  target,
  input,
  type,
  value,
  size,
  className,
  children,
  onClick,
  disabled,
}) => {
  const btnSize = () => {
    if (size === "sm") {
      return "btn-sm"
    } else if (size === "lg") {
      return "btn-lg"
    } else {
      return ""
    }
  }
  const renderButton = () => {
    if (link) {
      return (
        <Link
          to={link}
          target={target}
          className={`btn btn-${variant} ${btnSize()} ${className}`}
        >
          {children}
        </Link>
      )
    } else if (input) {
      return (
        <input
          type={type}
          value={value}
          className={`btn btn-${variant} ${btnSize()} ${className}`}
        />
      )
    } else {
      return (
        <button
          className={`btn btn-${variant} ${btnSize()} ${className}`}
          onClick={onClick}
          disabled={disabled}
        >
          {children}
        </button>
      )
    }
  }

  return <>{renderButton()}</>
}

Button.propTypes = {
  forwardKey: PropTypes.string, // Set key
  variant: PropTypes.string.isRequired, // Set variant (based on Bootstrap classes)
  link: PropTypes.string, // Set button link / href
  input: PropTypes.bool, // Set button as <input> if true
  type: PropTypes.string, // Set <input> button type attribute
  value: PropTypes.string, // Set <input> button value attribute
  size: PropTypes.oneOf(["", "sm", "lg"]), // Set button size
  className: PropTypes.string,
  children: PropTypes.any,
}

Button.defaultProps = {
  input: false,
  size: "",
  className: "",
}

// Button Group
export const ButtonGroup = ({
  size,
  btnMargin,
  stackedSmDown,
  className,
  children,
}) => {
  const btnGroupSize = () => {
    if (size === "sm") {
      return "btn-group-sm"
    } else if (size === "lg") {
      return "btn-group-lg"
    } else {
      return ""
    }
  }
  return (
    <div
      className={`btn-group 
        ${btnGroupSize()} 
        ${btnMargin} 
        ${stackedSmDown && "d-sm-down-stacked"} 
        ${className}
      `}
    >
      {children}
    </div>
  )
}

ButtonGroup.propTypes = {
  size: PropTypes.oneOf(["", "sm", "lg"]), // Set button size
  btnMargin: PropTypes.string, // Set button margin (e.g. .mx-1, .mx-2, .mx-3, .mx-10px, .mx-20px, etc.)
  stackedSmDown: PropTypes.bool, // Set button to be stacked on sm down (retaining margin set by .mx-*)
  className: PropTypes.string, // Set optional classes
  children: PropTypes.any,
}

ButtonGroup.defaultProps = {
  size: "",
  stackedSmDown: false,
  className: "",
}
