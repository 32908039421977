import React, {useContext, useState} from "react"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"

import { ImgRatio, Link, BackgroundImage, AntiLink } from "../utils/utils"
import { Section, Container } from "../grid/grid"
import { LangContext } from "../../context/langContext"
import { replaceUnicode } from "../utils/utils"

import imgPlaceholder11 from "../../assets/img/common/img_placeholder_1-1.png"
import imgPlaceholder21 from "../../assets/img/common/img_placeholder_2-1.png"
import imgPlaceholder32 from "../../assets/img/common/img_placeholder_3-2.png"

export const Card = ({
  id,
  forwardKey,
  variant,
  colLeft,
  colRight,
  label,
  labelClassName,
  title,
  titleClassName,
  text,
  textClassName,
  img,
  imgHeight,
  imgOverlay,
  link,
  cardBgClassName,
  cardBodyClassName,
  className,
  children,
  caption,
  captionClassName,
  clickable,
  clickableClassName,
}) => {
  return (
    <Link
      to={link}
      className={`card card-${variant} ${className}`}
      id={id}
      key={forwardKey}
    >
      <div className="row row-0">
        <div className={`col-bg ${colLeft}`}>
          {img && (
            <div className={`card-bg ${cardBgClassName} ${clickableClassName}`} onClick={clickable}>
              <BackgroundImage
                Tag="div"
                fluid={img}
                className={`card-bg-img ${imgHeight}`}
              >
                <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
                <ImgRatio />
              </BackgroundImage>
              {caption && (
                <div className={`card-caption ${captionClassName}`}>
                  <ReactMarkdown source={caption} escapeHtml={false} />
                </div>
              )}
            </div>
          )}
        </div>
        <div className={`col-body ${colRight}`}>
          {(title || text) && (
            <div className={`card-body ${cardBodyClassName}`}>
              {label && (
                <span className={`card-label ${labelClassName}`}>{label}</span>
              )}
              {title && (
                <h3 
                  className={`card-title ${titleClassName}`}
                  dangerouslySetInnerHTML={{__html: title,}}
                ></h3>
              )}
              {text && (
                <div className={`card-text ${textClassName}`}>
                  {/* <ReactMarkdown source={text} escapeHtml={false} /> */}
                  <p dangerouslySetInnerHTML={{ __html: text }}></p>
                </div>
              )}
              {children}
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

Card.propTypes = {
  id: PropTypes.string, // Set card id
  variant: PropTypes.oneOf(["basic", "boxless", "overlay"]), // Set card variant
  colLeft: PropTypes.string, // Set col left utilities classes (e.g. .col-md-6, .col-lg-4, etc.)
  colRight: PropTypes.string, // Set col right utilities classes (e.g. .col-md-6, .col-lg-8, etc.)
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  img: PropTypes.any,
  imgHeight: PropTypes.string, // Set .card-bg height / ratio with sizing utilities classes (e.g. .h-auto, .h-sm-100px, h-md-vw-100, .h-ratio-1-1, etc.)
  imgOverlay: PropTypes.string, // Set cover overlay %
  link: PropTypes.string, // Set card link / href
  cardBgClassName: PropTypes.string,
  cardBodyClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  caption: PropTypes.string,
  captionClassName: PropTypes.string,
}

Card.defaultProps = {
  id: "",
  variant: "basic",
  colLeft: "col-12",
  colRight: "col-12",
  label: "",
  labelClassName: "",
  titleClassName: "",
  textClassName: "",
  cardBgClassName: "",
  cardBodyClassName: "",
  imgHeight: "h-ratio-3-2",
  imgOverlay: "bottom",
  link: "",
  className: "",
  captionClassName: "",
}

export const CardNavBottom = ({
  id,
  forwardKey,
  variant,
  colLeft,
  colRight,
  label,
  labelClassName,
  title,
  titleClassName,
  text,
  textClassName,
  img,
  imgHeight,
  imgOverlay,
  link,
  cardBgClassName,
  cardBodyClassName,
  className,
  children,
  caption,
  captionClassName,
}) => {
  return (
    <Link
      to={link}
      className={`card card-${variant} ${className}`}
      id={id}
      key={forwardKey}
    >
      <div className="row row-0">
        <div className={`col-bg ${colLeft}`}>
          {img && (
            <div className={`card-bg ${cardBgClassName}`}>
              <div className={`card-bg-img ${imgHeight}`} >
                <img data-src={img} alt={title} className={`lazyload`} width="100" height="100" style={{backgroundSize: `cover`, width: `100%`, height: `auto`, objectFit: `cover`}}/>
                <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
              </div>
              {/* <BackgroundImage
                Tag="img"
                fluid={img}
                className={`card-bg-img ${imgHeight}`}
                alt={title}
              >
                <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
                <ImgRatio />
              </BackgroundImage> */}
              {caption && (
                <div className={`card-caption ${captionClassName}`}>
                  <ReactMarkdown source={caption} escapeHtml={false} />
                </div>
              )}
            </div>
          )}
        </div>
        <div className={`col-body ${colRight}`}>
          {(title || text) && (
            <div className={`card-body ${cardBodyClassName}`}>
              {label && (
                <span className={`card-label ${labelClassName}`}>{label}</span>
              )}
              {title && (
                <h3 className={`card-title ${titleClassName}`}>
                  {title
                    .replace(/(<([^>]+)>)/gi, "")
                    .replace(/&#8211;/gi, "-")
                    .replace(/&#038;/gi, "&")
                    .replace(/&#8216;/gi, "'")
                    .replace(/&#8221;/gi, '"')}
                </h3>
              )}
              {text && (
                <div className={`card-text ${textClassName}`}>
                  {/* <ReactMarkdown source={text} escapeHtml={false} /> */}
                  <p dangerouslySetInnerHTML={{ __html: text }}></p>
                </div>
              )}
              {children}
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

CardNavBottom.propTypes = {
  id: PropTypes.string, // Set card id
  variant: PropTypes.oneOf(["basic", "boxless", "overlay"]), // Set card variant
  colLeft: PropTypes.string, // Set col left utilities classes (e.g. .col-md-6, .col-lg-4, etc.)
  colRight: PropTypes.string, // Set col right utilities classes (e.g. .col-md-6, .col-lg-8, etc.)
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  img: PropTypes.any,
  imgHeight: PropTypes.string, // Set .card-bg height / ratio with sizing utilities classes (e.g. .h-auto, .h-sm-100px, h-md-vw-100, .h-ratio-1-1, etc.)
  imgOverlay: PropTypes.string, // Set cover overlay %
  link: PropTypes.string, // Set card link / href
  cardBgClassName: PropTypes.string,
  cardBodyClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  caption: PropTypes.string,
  captionClassName: PropTypes.string,
}

CardNavBottom.defaultProps = {
  id: "",
  variant: "basic",
  colLeft: "col-12",
  colRight: "col-12",
  label: "",
  labelClassName: "",
  titleClassName: "",
  textClassName: "",
  cardBgClassName: "",
  cardBodyClassName: "",
  imgHeight: "h-ratio-3-2",
  imgOverlay: "bottom",
  link: "",
  className: "",
  captionClassName: "",
}

export const CardAmp = ({
  id,
  forwardKey,
  variant,
  colLeft,
  colRight,
  label,
  labelClassName,
  title,
  titleClassName,
  text,
  textClassName,
  img,
  imgHeight,
  imgOverlay,
  link,
  cardBgClassName,
  cardBodyClassName,
  className,
  children,
  caption,
  captionClassName,
}) => {
  return (
    <Link
      to={link}
      className={`card card-${variant} ${className}`}
      id={id}
      key={forwardKey}
    >
      <div className="row row-0">
        <div className={`col-bg ${colLeft}`}>
          {img && (
            <div className={`card-bg ${cardBgClassName}`}>
              <amp-img
                class="img-fluid"
                src={img}
                alt={title}
                // layout="responsive"
                width="380"
                height="155"
              >
              </amp-img>
              {caption && (
                <div className={`card-caption ${captionClassName}`}>
                  <ReactMarkdown source={caption} escapeHtml={false} />
                </div>
              )}
            </div>
          )}
        </div>
        <div className={`col-body ${colRight}`}>
          {(title || text) && (
            <div className={`card-body ${cardBodyClassName}`}>
              {label && (
                <span className={`card-label ${labelClassName}`}>{label}</span>
              )}
              {title && (
                <h3 className={`card-title ${titleClassName}`}>
                  {title
                    .replace(/(<([^>]+)>)/gi, "")
                    .replace(/&#8211;/gi, "-")
                    .replace(/&#038;/gi, "&")
                    .replace(/&#8216;/gi, "'")
                    .replace(/&#8221;/gi, '"')}
                </h3>
              )}
              {text && (
                <div className={`card-text ${textClassName}`}>
                  {/* <ReactMarkdown source={text} escapeHtml={false} /> */}
                  <p dangerouslySetInnerHTML={{ __html: text }}></p>
                </div>
              )}
              {children}
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

CardAmp.propTypes = {
  id: PropTypes.string, // Set card id
  variant: PropTypes.oneOf(["basic", "boxless", "overlay"]), // Set card variant
  colLeft: PropTypes.string, // Set col left utilities classes (e.g. .col-md-6, .col-lg-4, etc.)
  colRight: PropTypes.string, // Set col right utilities classes (e.g. .col-md-6, .col-lg-8, etc.)
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  img: PropTypes.any,
  imgHeight: PropTypes.string, // Set .card-bg height / ratio with sizing utilities classes (e.g. .h-auto, .h-sm-100px, h-md-vw-100, .h-ratio-1-1, etc.)
  imgOverlay: PropTypes.string, // Set cover overlay %
  link: PropTypes.string, // Set card link / href
  cardBgClassName: PropTypes.string,
  cardBodyClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  caption: PropTypes.string,
  captionClassName: PropTypes.string,
}

CardAmp.defaultProps = {
  id: "",
  variant: "basic",
  colLeft: "col-12",
  colRight: "col-12",
  label: "",
  labelClassName: "",
  titleClassName: "",
  textClassName: "",
  cardBgClassName: "",
  cardBodyClassName: "",
  imgHeight: "h-ratio-3-2",
  imgOverlay: "bottom",
  link: "",
  className: "",
  captionClassName: "",
}

export const CardSML = ({
  id,
  forwardKey,
  variant,
  colLeft,
  colRight,
  label,
  labelClassName,
  title,
  titleClassName,
  text,
  textClassName,
  img,
  imgHeight,
  logo,
  imgOverlay,
  link,
  cardBgClassName,
  cardBodyClassName,
  className,
  children,
}) => {
  return (
    <Link
      to={link}
      className={`card card-${variant} ${className}`}
      id={id}
      key={forwardKey}
    >
      <div className="row row-0">
        <div className={`col-bg ${colLeft}`}>
          {img && (
            <div className={`card-bg ${cardBgClassName}`}>
              <BackgroundImage
                Tag="div"
                fluid={img}
                className={`card-bg-img ${imgHeight}`}
              >
                <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
                <ImgRatio />
              </BackgroundImage>
            </div>
          )}
        </div>
        <div className={`col-body ${colRight}`}>
          {(title || text) && (
            <div className={`card-body ${cardBodyClassName}`}>
              <div>
                {label && (
                  <span className={`card-label ${labelClassName}`}>
                    {label}
                  </span>
                )}
                {title && (
                  <h3 className={`card-title ${titleClassName}`}>
                    {title
                      .replace(/(<([^>]+)>)/gi, "")
                      .replace(/&#8211;/gi, "-")
                      .replace(/&#038;/gi, "&")
                      .replace(/&#8216;/gi, "'")
                      .replace(/&#8221;/gi, '"')}
                  </h3>
                )}
                {text && (
                  <div className={`card-text ${textClassName}`}>
                    <ReactMarkdown source={text} escapeHtml={false} />
                  </div>
                )}
              </div>
              <div>{logo && <img src={logo} className="awards-logo" />}</div>
              {children}
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

CardSML.propTypes = {
  id: PropTypes.string, // Set card id
  variant: PropTypes.oneOf(["basic", "boxless", "overlay"]), // Set card variant
  colLeft: PropTypes.string, // Set col left utilities classes (e.g. .col-md-6, .col-lg-4, etc.)
  colRight: PropTypes.string, // Set col right utilities classes (e.g. .col-md-6, .col-lg-8, etc.)
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  img: PropTypes.any,
  imgHeight: PropTypes.string, // Set .card-bg height / ratio with sizing utilities classes (e.g. .h-auto, .h-sm-100px, h-md-vw-100, .h-ratio-1-1, etc.)
  imgOverlay: PropTypes.string, // Set cover overlay %
  link: PropTypes.string, // Set card link / href
  cardBgClassName: PropTypes.string,
  cardBodyClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
}

CardSML.defaultProps = {
  id: "",
  variant: "basic",
  colLeft: "col-12",
  colRight: "col-12",
  label: "",
  labelClassName: "",
  titleClassName: "",
  textClassName: "",
  cardBgClassName: "",
  cardBodyClassName: "",
  imgHeight: "h-ratio-3-2",
  imgOverlay: "bottom",
  link: "",
  className: "",
}

export const CardAwards = ({ data }) => {
  const [allAwardsOffset, setAllAwardsOffset] = useState(1)
  let title
  let awardsData = []
  let awardsDataV2 = []

  title = data.innerBlocks.find(x => x.name === "core/heading")
    ? data.innerBlocks.find(x => x.name === "core/heading").attributes.content
    : "Title"

  data.innerBlocks.map(res => {
    if (res.name === "core/group") {
      awardsData.push({
        title: res.innerBlocks.find(x => x.name === "core/heading")
          ? res.innerBlocks.find(x => x.name === "core/heading").attributes
              .content
          : "Title",
        year: res.innerBlocks.find(x => x.name === "core/image")
          ? res.innerBlocks.find(x => x.name === "core/image").attributes
              .caption
          : "",
        location: res.innerBlocks.find(x => x.name === "core/paragraph")
          ? res.innerBlocks.find(x => x.name === "core/paragraph").attributes
              .content
          : "",
        image: res.innerBlocks.find(x => x.name === "core/image")
          ? res.innerBlocks.find(x => x.name === "core/image").attributes.url
          : data.imgCoverMain.childImageSharp.fluid,
      })
    } else if (res.name === "acf/col-image-w-text") {
      awardsDataV2.push(res.acf)
    }
  })

  const Pagination = ({ hasPreviousPage, hasNextPage, prevPage, nextPage }) => {
    return (
      <div className="d-flex justify-content-end">
        <div className="paginate">
          <button
            className="btn btn-primary pagination-button"
            disabled={hasPreviousPage}
            onClick={prevPage}
          >
            <i className="fas fa-chevron-left" />
          </button>
          <button
            className="btn btn-primary pagination-button"
            disabled={hasNextPage}
            onClick={nextPage}
          >
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      </div>
    )
  }

  const allAwardsNextPage = () => {
    if (awardsDataV2[allAwardsOffset * 10] !== undefined) {
      setAllAwardsOffset(allAwardsOffset + 1)
    }
  }
  const allAwardsPrevPage = () => {
    if (allAwardsOffset >= 1) {
      setAllAwardsOffset(allAwardsOffset - 1)
    }
  }

  return (
    <Section className="awards">
      <Container>
        <div className="row">
          <div className="col-lg-2 col-xl-3 mb-3 mb-lg-0">
            <h2 className="awards-category h2">{title}</h2>
          </div>
          <div className="col-lg-10 col-xl-9">
            {awardsDataV2.length > 0 &&
              awardsDataV2.map((item, i) => {
                if (i > allAwardsOffset * 10 - 10 && i < allAwardsOffset * 10 || i === 0){
                return (
                  <CardSML
                    key={`awards-${i}`}
                    label={item.label}
                    title={item.title}
                    text={item.text}
                    img={item.image && item.image.sourceUrl}
                    logo={item.logo && item.logo.sourceUrl}
                    colLeft="col-12 col-md-5"
                    colRight="col-12 col-md-7 details-info"
                    className="card-award pb-3"
                  />
                )
                }
              })}
          </div>
          {awardsDataV2.length > 10 && (
            <div className="col-12 mb-5">
              <Pagination
                hasPreviousPage={allAwardsOffset === 1}
                hasNextPage={awardsDataV2[allAwardsOffset * 10] === undefined}
                prevPage={allAwardsPrevPage}
                nextPage={allAwardsNextPage}
              />
            </div>
          )}
        </div>
      </Container>
    </Section>
  )
}

export const CardCustom = ({
  id,
  forwardKey,
  variant,
  colLeft,
  colRight,
  label,
  labelClassName,
  title,
  titleClassName,
  text,
  textClassName,
  img,
  imgHeight,
  imgOverlay,
  link,
  cardBgClassName,
  cardBodyClassName,
  className,
  children,
}) => {
  return (
    <Link
      to={link}
      className={`card card-${variant} ${className}`}
      id={id}
      key={forwardKey}
    >
      <div className="row row-0">
        <div className={`col-bg ${colLeft}`}>
          {img && (
            <div className={`card-bg ${cardBgClassName}`}>
              <BackgroundImage
                Tag="div"
                fluid={img}
                className={`card-bg-img ${imgHeight}`}
              >
                <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
                <ImgRatio />
              </BackgroundImage>
            </div>
          )}
        </div>
        <div className={`col-body ${colRight}`}>
          {(title || text) && (
            <div className={`card-body ${cardBodyClassName}`}>
              {label && (
                <span className={`card-label ${labelClassName}`}>{label}</span>
              )}
              {title && (
                <h3 className={`card-title ${titleClassName}`}>
                  {title
                    .replace(/(<([^>]+)>)/gi, "")
                    .replace(/&#8211;/gi, "-")
                    .replace(/&#038;/gi, "&")
                    .replace(/&#8216;/gi, "'")
                    .replace(/&#8221;/gi, '"')}
                </h3>
              )}
              {text && (
                <div className={`card-text ${textClassName}`}>
                  {/* <ReactMarkdown source={text} escapeHtml={false} /> */}
                  <p dangerouslySetInnerHTML={{ __html: text }}></p>
                </div>
              )}
            </div>
          )}
        </div>
        {children}
      </div>
    </Link>
  )
}

CardCustom.propTypes = {
  id: PropTypes.string, // Set card id
  variant: PropTypes.oneOf(["basic", "boxless", "overlay"]), // Set card variant
  colLeft: PropTypes.string, // Set col left utilities classes (e.g. .col-md-6, .col-lg-4, etc.)
  colRight: PropTypes.string, // Set col right utilities classes (e.g. .col-md-6, .col-lg-8, etc.)
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  img: PropTypes.any,
  imgHeight: PropTypes.string, // Set .card-bg height / ratio with sizing utilities classes (e.g. .h-auto, .h-sm-100px, h-md-vw-100, .h-ratio-1-1, etc.)
  imgOverlay: PropTypes.string, // Set cover overlay %
  link: PropTypes.string, // Set card link / href
  cardBgClassName: PropTypes.string,
  cardBodyClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
}

CardCustom.defaultProps = {
  id: "",
  variant: "basic",
  colLeft: "col-12",
  colRight: "col-12",
  label: "",
  labelClassName: "",
  titleClassName: "",
  textClassName: "",
  cardBgClassName: "",
  cardBodyClassName: "",
  imgHeight: "h-ratio-3-2",
  imgOverlay: "bottom",
  link: "",
  className: "",
}

export const CardBrochure = ({
  id,
  forwardKey,
  variant,
  colLeft,
  colRight,
  label,
  labelClassName,
  title,
  titleClassName,
  text,
  textClassName,
  img,
  imgHeight,
  imgOverlay,
  link,
  cardBgClassName,
  cardBodyClassName,
  className,
  children,
}) => {
  return (
    <Link
      to={link}
      className={`card card-${variant} ${className}`}
      id={id}
      key={forwardKey}
    >
      <div className="row row-0">
        <div className={`col-bg ${colLeft}`}>
          {img && (
            <div className={`card-bg ${cardBgClassName}`}>
              <BackgroundImage
                Tag="div"
                fluid={img}
                className={`card-bg-img ${imgHeight}`}
              >
                <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
                <ImgRatio />
              </BackgroundImage>
            </div>
          )}
        </div>
        <div className={`col-body ${colRight}`}>
          {(title || text) && (
            <div className={`card-body ${cardBodyClassName}`}>
              {label && (
                <span className={`card-label ${labelClassName}`}>{label}</span>
              )}
              {title && (
                <h3 className={`card-title ${titleClassName}`}>
                  {title
                    .replace(/(<([^>]+)>)/gi, "")
                    .replace(/&#8211;/gi, "-")
                    .replace(/&#038;/gi, "&")
                    .replace(/&#8216;/gi, "'")
                    .replace(/&#8221;/gi, '"')}
                </h3>
              )}
              {text && (
                <div className={`card-text ${textClassName}`}>
                  {/* <ReactMarkdown source={text} escapeHtml={false} /> */}
                  <p dangerouslySetInnerHTML={{ __html: text }}></p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {children}
    </Link>
  )
}

CardBrochure.propTypes = {
  id: PropTypes.string, // Set card id
  variant: PropTypes.oneOf(["basic", "boxless", "overlay"]), // Set card variant
  colLeft: PropTypes.string, // Set col left utilities classes (e.g. .col-md-6, .col-lg-4, etc.)
  colRight: PropTypes.string, // Set col right utilities classes (e.g. .col-md-6, .col-lg-8, etc.)
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  img: PropTypes.any,
  imgHeight: PropTypes.string, // Set .card-bg height / ratio with sizing utilities classes (e.g. .h-auto, .h-sm-100px, h-md-vw-100, .h-ratio-1-1, etc.)
  imgOverlay: PropTypes.string, // Set cover overlay %
  link: PropTypes.string, // Set card link / href
  cardBgClassName: PropTypes.string,
  cardBodyClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
}

CardBrochure.defaultProps = {
  id: "",
  variant: "basic",
  colLeft: "col-12",
  colRight: "col-12",
  label: "",
  labelClassName: "",
  titleClassName: "",
  textClassName: "",
  cardBgClassName: "",
  cardBodyClassName: "",
  imgHeight: "h-ratio-3-2",
  imgOverlay: "bottom",
  link: "",
  className: "",
}

export const CardSearchPrice = ({
  id,
  forwardKey,
  variant,
  colLeft,
  colRight,
  facilities,
  facilitiesClassName,
  priceProject,
  priceProjectClassName,
  label,
  labelClassName,
  locations,
  locationsClassName,
  title,
  titleClassName,
  text,
  textClassName,
  img,
  imgHeight,
  imgOverlay,
  link,
  cardBgClassName,
  cardBodyClassName,
  className,
  children,
}) => {
  const currencyFormat = (money) => {
    return new Intl.NumberFormat('id-ID',
      { minimumFractionDigits: 0 }
    ).format(money);
  }
  return (
    <Link
      to={link}
      className={`card card-${variant} ${className}`}
      id={id}
      key={forwardKey}
    >
      <div className="row row-0">
        <div className={`col-bg ${colLeft}`}>
          {img && (
            <div className={`card-bg ${cardBgClassName}`}>
              <BackgroundImage
                Tag="div"
                fluid={img}
                className={`card-bg-img ${imgHeight}`}
              >
                <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
                <ImgRatio />
              </BackgroundImage>
            </div>
          )}
        </div>
        <div className={`col-body ${colRight}`}>
          {(title || text) && (
            <div className={`card-body ${cardBodyClassName}`}>
              {title && (
                <h3 className={`card-title ${titleClassName}`}>
                  {title
                    .replace(/(<([^>]+)>)/gi, "")
                    .replace(/&#8211;/gi, "-")
                    .replace(/&#038;/gi, "&")
                    .replace(/&#8216;/gi, "'")
                    .replace(/&#8221;/gi, '"')}
                </h3>
              )}
              {(label || locations) && (
                <span className={`card-label-pr ${labelClassName}`}>{label}&nbsp;•&nbsp;<span className={`card-location ${locationsClassName}`}>{locations}</span></span>
              )}
              {priceProject && priceProject !== null? (
                <span className="card-price-wrapper">
                  <span className="card-desc-price" style={{fontSize:".725rem"}}>Starts From&nbsp;&nbsp;</span>
                  <span className={`card-price-money ${priceProjectClassName}`} style={{fontSize:"20px", fontWeight:"bold"}}>Rp&nbsp;{currencyFormat(priceProject)}</span>
                </span>
              ):(
                <span className="card-price-wrapper">
                  <span className="card-desc-price" style={{fontSize:".725rem"}}>Starts From&nbsp;&nbsp;</span>
                  <span className={`card-price-money ${priceProjectClassName}`} style={{fontSize:"20px", fontWeight:"bold"}}>Reach Us Now!</span>
                </span>
              )}
              {facilities ? (
                facilities &&(
                  <div className="d-flex flex-wrap">
                    {facilities.map((item, index) => {
                      return (
                        <span
                          key={`facilities-${index}`}
                          className={facilitiesClassName}
                        >
                          <img src={item.image} width="20" className="mr-2"/>
                          <span className="mr-2" style={{fontSize:".725rem"}}>{item.details}</span>
                        </span>
                      )
                    })}
                </div>
                )
              ) : (
                <div></div>
              )}
              {children}
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

CardSearchPrice.propTypes = {
  id: PropTypes.string, // Set card id
  variant: PropTypes.oneOf(["basic", "boxless", "overlay"]), // Set card variant
  colLeft: PropTypes.string, // Set col left utilities classes (e.g. .col-md-6, .col-lg-4, etc.)
  colRight: PropTypes.string, // Set col right utilities classes (e.g. .col-md-6, .col-lg-8, etc.)
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  img: PropTypes.any,
  imgHeight: PropTypes.string, // Set .card-bg height / ratio with sizing utilities classes (e.g. .h-auto, .h-sm-100px, h-md-vw-100, .h-ratio-1-1, etc.)
  imgOverlay: PropTypes.string, // Set cover overlay %
  link: PropTypes.string, // Set card link / href
  cardBgClassName: PropTypes.string,
  cardBodyClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  locations: PropTypes.string,
  locationsClassName: PropTypes.string,
  priceProject: PropTypes.number,
  priceProjectClassName: PropTypes.string,
  facilities: PropTypes.array,
}

CardSearchPrice.defaultProps = {
  id: "",
  variant: "basic",
  colLeft: "col-12",
  colRight: "col-12",
  label: "",
  labelClassName: "",
  titleClassName: "",
  textClassName: "",
  cardBgClassName: "",
  cardBodyClassName: "",
  imgHeight: "h-ratio-3-2",
  imgOverlay: "bottom",
  link: "",
  className: "",
  locationsClassName: "",
  priceProjectClassName: "",
}

export const CardPrecious = ({
  id,
  forwardKey,
  variant,
  colBody,
  colLeft,
  colRight,
  label,
  labelClassName,
  title,
  titleClassName,
  text,
  textClassName,
  img,
  imgHeight,
  imgOverlay,
  link,
  cardBgClassName,
  cardBodyClassName,
  className,
  children,
  caption,
  captionClassName,
  descriptionClassName,
  description,
  tier,
  tierClassName,
  newProduct,
  newProductClassName,
  productInStock,
  productInStockClassName,
  nik,
  mobile,
  name,
}) => {
  return (
    <Link
      to={link}
      className={`card card-${variant} ${className}`}
      id={id}
      key={forwardKey}
    >
      <div className="row row-0">
        <div className={`col-bg ${colLeft}`}>
          {img && (
            <div className={`card-bg ${cardBgClassName}`}>
              <div className={`card-bg-img ${imgHeight}`}>
                <img src={img} width="200"/>
                <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
              </div>
              {caption && (
                <div className={`card-caption ${captionClassName}`}>
                  <ReactMarkdown source={caption} escapeHtml={false} />
                </div>
              )}
            </div>
          )}
        </div>
        <div className={`col-body ${colBody}`}>
          {title && (
            <h6 
              className={`card-title ${titleClassName}`} 
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            >
            </h6>
          )}
        </div>
        <div className={`col-details ${colRight}`}>
          {(label || text || description) && (
            <div className={`card-description ${cardBodyClassName}`}>
              {label && (
                <span className={`card-label ${labelClassName}`}>{label}</span>
              )}
              {text && (
                <div className={`card-text ${textClassName}`}>
                  {/* <ReactMarkdown source={text} escapeHtml={false} /> */}
                  <span>{text}</span>
                </div>
              )}
              {description && (
                <div className={`card-description ${descriptionClassName}`}>
                  {/* <ReactMarkdown source={text} escapeHtml={false} /> */}
                  <span>{description}</span>
                </div>
              )}
              {children}
            </div>
          )}
          {(tier || newProduct || productInStock || nik) && (
            <div className={`card-description ${cardBodyClassName}`}>
              {tier && (
                <span className={`card-label ${tierClassName}`}><span>Tier : </span>{tier}</span>
              )}
              {newProduct && (
                <div className={`card-text ${newProductClassName}`}>
                  {/* <ReactMarkdown source={text} escapeHtml={false} /> */}
                  <span><span>New Product : </span>{newProduct}</span>
                </div>
              )}
              {productInStock && (
                <div className={`card-description ${productInStockClassName}`}>
                  {/* <ReactMarkdown source={text} escapeHtml={false} /> */}
                  <span><span>Product in Stock : </span>{productInStock}</span>
                </div>
              )}
              {nik && (
                <span className={`card-label ${tierClassName}`}><span>NIK : </span>{nik}</span>
              )}
              {name && (
                <span className={`card-label ${tierClassName}`}><span>Name : </span>{name}</span>
              )}
              {mobile && (
                <span className={`card-label ${tierClassName}`}><span>Phone Number : </span>{` +${mobile}`}</span>
              )}
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

CardPrecious.propTypes = {
  id: PropTypes.string, // Set card id
  variant: PropTypes.oneOf(["basic", "boxless", "overlay"]), // Set card variant
  colLeft: PropTypes.string, // Set col left utilities classes (e.g. .col-md-6, .col-lg-4, etc.)
  colRight: PropTypes.string, // Set col right utilities classes (e.g. .col-md-6, .col-lg-8, etc.)
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  img: PropTypes.any,
  imgHeight: PropTypes.string, // Set .card-bg height / ratio with sizing utilities classes (e.g. .h-auto, .h-sm-100px, h-md-vw-100, .h-ratio-1-1, etc.)
  imgOverlay: PropTypes.string, // Set cover overlay %
  link: PropTypes.string, // Set card link / href
  cardBgClassName: PropTypes.string,
  cardBodyClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  caption: PropTypes.string,
  captionClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
  description: PropTypes.string,
  tier: PropTypes.string,
  tierClassName: PropTypes.string,
  newProduct: PropTypes.string,
  newProductClassName: PropTypes.string,
  productInStock: PropTypes.string,
  productInStockClassName: PropTypes.string,
}

CardPrecious.defaultProps = {
  id: "",
  variant: "basic",
  colBody: "col-12",
  colLeft: "col-12",
  colRight: "col-12",
  label: "",
  labelClassName: "",
  titleClassName: "",
  textClassName: "",
  cardBgClassName: "",
  cardBodyClassName: "",
  imgHeight: "h-ratio-3-2",
  imgOverlay: "bottom",
  link: "",
  className: "",
  captionClassName: "",
  descriptionClassName: "",
  description: "",
}

export const CardTnB = ({
  forwardKey,
  id,
  variant,  
  link,
  className,
  assetDetail,
  productDetail,
  incentiveDetail,
}) => {
  return (
    <Link
      to={link}
      className={`card card-${variant} mt-3 mb-3 ${className}`}
      id={id}
      key={forwardKey}
    >
      <div className="row row-0">
        {assetDetail && assetDetail.map(item => {
          return(
            <div className="col-md-4">
              <div className="col-md-12">
                <img src={item?.image?.sourceUrl} />
              </div>
              <div className="col-md-12">
                <span className="mon-text-style">Total Asset</span><br />
                <span className="mon-text-style font-weight-bold">{item?.totalAsset}</span>
              </div>
            </div>
          )
        })}
        {productDetail && productDetail.map(item => {
          return(
            <div className="col-md-4">
              <div className="col-md-12">
                <img src={item?.image?.sourceUrl} />
              </div>
              <div className="col-md-12">
                <span className="mon-text-style">Product in Stock</span><br />
                <span className="mon-text-style font-weight-bold">{item?.productInStock}</span><br />
                <span className="mon-text-style">New Product</span><br />
                <span className="mon-text-style font-weight-bold">{item?.newProduct}</span>
              </div>
            </div>
          )
        })}
        {incentiveDetail && incentiveDetail.map(item => {
          return(
            <div className="col-md-4">
              <div className="col-md-12">
                <img src={item?.image?.sourceUrl} />
              </div>
              <div className="col-md-12">
                <span className="mon-text-style">Referral Incentive</span><br />
                <span className="mon-text-style font-weight-bold">{item?.refferalIncentive}</span><br />
              </div>
            </div>
          )
        })}
      </div>
    </Link>
  )
}

CardTnB.propTypes = {
  id: PropTypes.string, 
  variant: PropTypes.oneOf(["basic", "boxless", "overlay"]), 
  link: PropTypes.string,
  className: PropTypes.string,
  assetDetail: PropTypes.array,
  productDetail: PropTypes.array,
  incentiveDetail: PropTypes.array,
}

CardTnB.defaultProps = {
  id: "",
  variant: "basic",
  link: "",
  className: "",
}

export const CardNews = ({
  title,
  description,
  image,
  link,
  category,
  date,
  className,
  target,
}) => {
  const { lang } = useContext(LangContext)
  return typeof image === "string" ? (
    <div className={`${className}`}>
      <AntiLink to={link} target={target}>
        <div
          alt={title}
          className="card card-project"
          style={{
            background: `url(${image}) no-repeat center`,
            backgroundSize: "cover",
          }}
        >
          <div className="card-body">
            <h3 className="card-title">{replaceUnicode(title)}</h3>
            <div className="card-data">
              {category && (
                <div>
                  <i className="fa fa-map"></i> <span>{category}</span>
                </div>
              )}
              {date && (
                <div>
                  <i className="fal fa-map-marker-alt"></i> <span>{date}</span>
                </div>
              )}
            </div>
          </div>
          <div className="card-overlay">
            <h3 className="card-title">{title}</h3>
            <div className="card-data">
              {category && (
                <div>
                  <i className="fa fa-map"></i> <span>{category}</span>
                </div>
              )}
              {date && (
                <div>
                  <i className="fal fa-map-marker-alt"></i> <span>{date}</span>
                </div>
              )}
            </div>
            <div 
              className="card-text"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
            <button className="btn btn-link text-white fx-underline">
              {lang === "EN" ? "Explore More" : "Jelajahi Lebih Lanjut"}
            </button>
          </div>
          <img
            className="img-placeholder img-placeholder-1-1"
            src={imgPlaceholder11}
            alt="Placeholder"
            width="100"
            height="100"
          />
          <img
            className="img-placeholder img-placeholder-2-1"
            src={imgPlaceholder21}
            alt="Placeholder"
            width="100"
            height="100"
          />
          <img
            className="img-placeholder img-placeholder-3-2"
            src={imgPlaceholder32}
            alt="Placeholder"
            width="100"
            height="100"
          />
        </div>
      </AntiLink>
    </div>
  ):(
    <div className={`${className}`}>
      <AntiLink to={link} target={target}>
        <BackgroundImage alt={title} className="card card-project" fluid={image}>
          <div className="card-body">
            <h3 className="card-title">{title}</h3>
            <div className="card-data">
              {category && (
                <div>
                  <i className="fa fa-map"></i> <span>{category}</span>
                </div>
              )}
              {date && (
                <div>
                  <i className="fal fa-map-marker-alt"></i> <span>{date}</span>
                </div>
              )}
            </div>
          </div>
          <div className="card-overlay">
            <h3 className="card-title">{title}</h3>
            <div className="card-data">
              {category && (
                <div>
                  <i className="fa fa-map"></i> <span>{category}</span>
                </div>
              )}
              {date && (
                <div>
                  <i className="fal fa-map-marker-alt"></i> <span>{date}</span>
                </div>
              )}
            </div>
            <div
              className="card-text"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
            <button className="btn btn-link text-white fx-underline">
              {lang === "EN" ? "Explore More" : "Jelajahi Lebih Lanjut"}
            </button>
          </div>
          <img
            className="img-placeholder img-placeholder-1-1"
            src={imgPlaceholder11}
            alt="Placeholder"
            width="100"
            height="100"
          />
          <img
            className="img-placeholder img-placeholder-2-1"
            src={imgPlaceholder21}
            alt="Placeholder"
            width="100"
            height="100"
          />
          <img
            className="img-placeholder img-placeholder-3-2"
            src={imgPlaceholder32}
            alt="Placeholder"
            width="100"
            height="100"
          />
        </BackgroundImage>
      </AntiLink>
    </div>
  )
}

CardNews.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.any,
  link: PropTypes.string,
  category: PropTypes.string,
  date: PropTypes.string,
  className: PropTypes.string,
  target: PropTypes.string,
}
CardNews.defaultProps = {
  title: "",
  description: "",
  image: "",
  link: "",
  category: "",
  date: "",
  className: "",
  target: "_self",
}
