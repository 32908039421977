/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import { replaceUnicode } from "./utils/utils"

// import logoIcon from "../assets/ico/favicon.jpg"

function SEO({
  ampUrl,
  url,
  title,
  fbTitle,
  description,
  twitterTitle,
  fbDescription,
  twitterDescription,
  twitterImg = "https://sinarmasland.com/static/sml-plaza-6a6e8ebe7eb4ad249e70d5149c18fcc0.png",
  fbImg = "https://sinarmasland.com/static/sml-plaza-6a6e8ebe7eb4ad249e70d5149c18fcc0.png",
  img = "https://sinarmasland.com/static/sml-plaza-6a6e8ebe7eb4ad249e70d5149c18fcc0.png",
  location,
  type = "website",
  schemaMarkup,
}) {
  const seoDefaultImg =
    location?.origin
      ? `${location.origin}/static/sml-plaza-6a6e8ebe7eb4ad249e70d5149c18fcc0.png`
      : `https://sinarmasland.com/static/sml-plaza-6a6e8ebe7eb4ad249e70d5149c18fcc0.png`

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
        }
      }
    }
  `)

  const metaDescription =
    replaceUnicode(description) || site.siteMetadata.description

  const metaFbDescription =
    replaceUnicode(fbDescription) || site.siteMetadata.description

  const metaTwitterDescription =
    replaceUnicode(twitterDescription) || site.siteMetadata.description

  const facebookPixelAllPagesDataLayer = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "fbPageview",
      page_url: window.location.href,
      page_title: title,
    })
    console.log("LOGGER_dataLayers === ", window.dataLayer)
  }

  useEffect(() => {
    facebookPixelAllPagesDataLayer()
  }, [])

  return (
    <Helmet defer={false}>
      {/* GENERAL */}
      {/* <meta charSet="utf-8" /> */}
      <title>{`${replaceUnicode(title) || title} | ${site.siteMetadata.title}`}</title>
      <meta name="description" content={metaDescription} />
      {!ampUrl ? (<link rel="canonical" href={url} />) : ("")}
      <meta name="robots" content="index, follow" />

      {/* FACEBOOK */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={replaceUnicode(fbTitle) || fbTitle || site.siteMetadata.title} />
      <meta property="og:image" content={fbImg && typeof fbImg === "object" ? seoDefaultImg : fbImg || img} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content={replaceUnicode(fbTitle) || fbTitle || site.siteMetadata.title} />
      <meta property="og:description" content={metaFbDescription} />
      <meta property="fb:app_id" content={process.env.FB_APP_ID} />

      {/* TWITTER */}
      <meta
        property="twitter:title"
        content={`${replaceUnicode(twitterTitle) || twitterTitle || site.siteMetadata.title}`}
      />
      <meta name="twitter:image:src" content={fbImg && typeof twitterImg === "object" ? seoDefaultImg : twitterImg || img} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@sinarmas_land" />
      <meta name="twitter:site:id" content="@sinarmas_land" />
      <meta name="twitter:creator" content="@sinarmas_land" />
      <meta name="twitter:description" content={metaTwitterDescription} />
      <meta property="twitter:url" content={url || "https://sinarmasland.com/"} />
      {schemaMarkup &&
        <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
      }
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  ampUrl: false,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  ampUrl: PropTypes.bool,
}

export default SEO
