import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import GatsbyLink from "gatsby-link"
import GatsbyBackgroundImage from "gatsby-background-image"

import imgRatio11 from "../../assets/img/common/img_ratio_1-1.png"
import imgRatio21 from "../../assets/img/common/img_ratio_2-1.png"
import imgRatio32 from "../../assets/img/common/img_ratio_3-2.png"

export const ImgRatio = () => {
  return (
    <>
      <img
        className="img-ratio img-ratio-1-1"
        src={imgRatio11}
        alt="Ratio Placeholder"
        width="100"
        height="100"
      />
      <img
        className="img-ratio img-ratio-2-1"
        src={imgRatio21}
        alt="Ratio Placeholder"
        width="100"
        height="100"
      />
      <img
        className="img-ratio img-ratio-3-2"
        src={imgRatio32}
        alt="Ratio Placeholder"
        width="100"
        height="100"
      />
    </>
  )
}

export const useMedia = query => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches)
  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => setMatches(media.matches)
    media.addListener(listener)
    return () => media.removeListener(listener)
  }, [query, matches])
  return matches
}

export const Link = props => {
  if (props.to !== null && props.to !== undefined && props.to.startsWith("/")) {
    return <GatsbyLink {...props}>{props.children}</GatsbyLink>
  } else if (props.to === "") {
    return <div {...props}>{props.children}</div>
  } else {
    return (
      <a
        {...props}
        href={
          props.to !== null &&
            props.to !== undefined &&
            props.to.split("https://").length > 1
            ? props.to
            : props.to.split("http://").length > 1
              ? props.to
              : `https://${props.to}`
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    )
  }
}

export const ButtonLink = ({ item, lang }) => {
  let buttons = item.innerBlocks.reduce((result, currentValue) => {
    result.push(currentValue.attributes)
    return result
  }, [])

  return buttons.map((item, i) => {
    const isExternalUrl = item.url && item.url.split(".").length > 1
    const hasHttp = item.url && item.url.split("https://").length > 1
    const hasHttps = item.url && item.url.split("http://").length > 1
    if (isExternalUrl) {
      if (hasHttp || hasHttps) {
        return (
          <>
            <a
              href={item.url}
              target="_blank"
              className="btn btn-link fx-underline"
              rel="noopener noreferrer"
              key={`${item.url}-${i}`}
            >
              {item.text} &nbsp;
              <i className="far fa-external-link"></i>
            </a>
          </>
        )
      }
      return (
        <>
          <a
            href={`http://${item.url}`}
            target="_blank"
            className="btn btn-link fx-underline"
            rel="noopener noreferrer"
            key={`${item.url}-${i}`}
          >
            {item.text} &nbsp;
            <i className="far fa-external-link"></i>
          </a>
        </>
      )
    } else {
      let pathname
      let url = item.url
      if (url && url !== "/news" && url !== "/development") {
        pathname = `${lang ? "/id" : ""}${item.url === null ? "" : item.url}`
      } else if (url === "/news" || url === "/development") {
        pathname = `${lang ? "/id" : ""}${item.url}`
      }

      return (
        <>
          <GatsbyLink
            key={`${item.url}-${i}`}
            to={pathname}
            className="btn btn-link fx-underline"
          >
            {item.text}
          </GatsbyLink>
        </>
      )
    }
  })
  // return <div></div>
}

export const AntiLink = props => {
  if (props.target === "_blank") {
    return (
      // prettier-ignore
      <a
        className={typeof props.children === "string" ? "btn btn-link" : props.className ? props.className : ""}
        href={props.to}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    )
  } else if (props.target === "") {
    const rawUrl = props.to && props.to
    const config = process.env.ROOT_URL
    const url = rawUrl.split(config)

    return (
      <GatsbyLink {...props} to={url.length > 1 ? url[1] : url[0]}>
        {props.children}
      </GatsbyLink>
    )
  } else {
    if (props.to && props.to.startsWith("/")) {
      return <GatsbyLink {...props}>{props.children}</GatsbyLink>
    } else if (props.to === "") {
      return <div {...props}>{props.children}</div>
    } else {
      return (
        <a {...props} href={props.to} target="_blank" rel="noopener noreferrer">
          {props.children}
        </a>
      )
    }
  }
}

export const BackgroundImage = props => {
  if (typeof props.fluid === "object") {
    return (
      <GatsbyBackgroundImage {...props}>{props.children}</GatsbyBackgroundImage>
    )
  } else {
    return (
      <div
        className={props.className}
        style={{
          background: `url(${props.fluid}) no-repeat center`,
          backgroundSize: "cover",
        }}
      >
        {props.children}
      </div>
    )
  }
}

BackgroundImage.propTypes = {
  fluid: PropTypes.any,
  Tag: PropTypes.string,
  className: PropTypes.string,
}

BackgroundImage.defaultProps = {
  Tag: "div",
  className: "",
}

export const replaceUnicode = text =>
  text
    ?.replace(/(<([^>]+)>)/gi, "")
    ?.replace(/&#8211;/gi, "-")
    ?.replace(/&#038;/gi, "&")
    ?.replace(/&#8216;/gi, "'")
    ?.replace(/&#8221;/gi, '"')
    ?.replace(/&#8217;/gi, "'")
    ?.replace(/&amp;/gi, "&")
    ?.replace(/&nbsp;/gi, ",")
    ?.replace(/&#8220;/gi, `"`)
