import React, { useEffect, useState, useLayoutEffect } from "react"
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"

import { Link } from "../utils/utils"
import { CardSlider } from "../card-slider/card-slider"
import { Card } from "../card/card"

import { useAuthContext } from "../../context/authContext"

const sapKey = process.env.SAP_API_KEY

const NavbarDesktop = ({
  brandLogoMain,
  brandLogoSecondary,
  brandLogoAlt,
  navBackground,
  fetchedData,
  lang = "EN",
}) => {
  const { user, setUser } = useAuthContext()
  const [loggedIn, setLoggedIn] = useState(user !== null)
  const [navbarSearch, setNavbarSearch] = useState({
    initial: false, // to change background navbar
    clicked: false, // Open / close form search
  })

  const [developmentToggle, setDevelopmentToggle] = useState({
    clicked: false,
  })
  
  const [corporateToggle, setCorporateToggle] = useState({
    clicked: false,
  })

  const [investorToggle, setInvestorToggle] = useState({
    clicked: false,
  })

  const handleClick = () => {
    if (navbarSearch.clicked === false) {
      setNavbarSearch({ clicked: true, intial: true })
    } else {
      setNavbarSearch({ clicked: false, intial: false })
    }
  }

  const handleDevelopmentClick = () => {
    if (developmentToggle.clicked === false) {
      setDevelopmentToggle({ clicked: true })
    } else {
      setDevelopmentToggle({ clicked: false })
    }
  }

  const handleCorporateClick = () => {
    if (corporateToggle.clicked === false) {
      setCorporateToggle({ clicked: true })
    } else {
      setCorporateToggle({ clicked: false })
    }
  }

  const handleInvestorClick = () => {
    if (investorToggle.clicked === false) {
      setInvestorToggle({ clicked: true })
    } else {
      setInvestorToggle({ clicked: false })
    }
  }

  const parseLink = url => {
    if (url) {
      const splitUrl = url.split("http")
      if (splitUrl.length > 1) {
        return url
      } else {
        return `${lang === "EN" ? "/" : "/id/"}investors${url}`
      }
    }
  }

  let developmentItems = []

  const menuItems =
    fetchedData.menus?.nodes[lang === "EN" ? 2 : 3]?.menuItems?.nodes
  const propertyTypes = fetchedData.propertyTypes.nodes

  const investorsItems = menuItems.find(x => x.label === "Investors")
    ?.childItems.nodes
  const investorsItemsId = menuItems.find(x => x.label === "Investor")
    ?.childItems.nodes
  const corporateItems = menuItems.find(x => x.label === "Corporate")
    ?.childItems.nodes
  const corporateItemsId = menuItems.find(x => x.label === "Perusahaan")
    ?.childItems.nodes

  for (let i = propertyTypes.length - 1; i >= 0; i--) {
    switch (propertyTypes[i].slug) {
      case "ecatalog":
        developmentItems[0] = (
          <Card
            key={i}
            variant="overlay"
            title={propertyTypes[i].name}
            titleClassName="h5 text-truncate"
            text={propertyTypes[i].acfPropertyType.excerpt}
            textClassName="font-size-sm"
            img={propertyTypes[i].acfPropertyType.image.sourceUrl}
            link={`https://ecatalog.sinarmasland.com`}
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      case "township":
        developmentItems[1] = (
          <Card
            key={i}
            variant="overlay"
            title={
              lang === "EN"
                ? propertyTypes[i].name
                : propertyTypes[i]?.translation?.name || propertyTypes[i].name
            }
            titleClassName="h5 text-truncate"
            text={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.excerpt
                : propertyTypes[i].translation?.acfPropertyType?.excerpt ||
                  propertyTypes[i].acfPropertyType.excerpt
            }
            textClassName="font-size-sm"
            img={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.image.sourceUrl
                : propertyTypes[i].translation?.acfPropertyType?.image
                    ?.sourceUrl ||
                  propertyTypes[i].acfPropertyType.image.sourceUrl
            }
            link={
              lang === "EN"
                ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
            }
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      case "residential":
        developmentItems[2] = (
          <Card
            key={i}
            variant="overlay"
            title={
              lang === "EN"
                ? propertyTypes[i].name
                : propertyTypes[i]?.translation?.name || propertyTypes[i].name
            }
            titleClassName="h5 text-truncate"
            text={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.excerpt
                : propertyTypes[i].translation?.acfPropertyType?.excerpt ||
                  propertyTypes[i].acfPropertyType.excerpt
            }
            textClassName="font-size-sm"
            img={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.image.sourceUrl
                : propertyTypes[i].translation?.acfPropertyType?.image
                    ?.sourceUrl ||
                  propertyTypes[i].acfPropertyType.image.sourceUrl
            }
            link={
              lang === "EN"
                ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
            }
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      case "office":
        developmentItems[3] = (
          <Card
            key={i}
            variant="overlay"
            title={
              lang === "EN"
                ? propertyTypes[i].name
                : propertyTypes[i]?.translation?.name || propertyTypes[i].name
            }
            titleClassName="h5 text-truncate"
            text={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.excerpt
                : propertyTypes[i].translation?.acfPropertyType?.excerpt ||
                  propertyTypes[i].acfPropertyType.excerpt
            }
            textClassName="font-size-sm"
            img={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.image.sourceUrl
                : propertyTypes[i].translation?.acfPropertyType?.image
                    ?.sourceUrl ||
                  propertyTypes[i].acfPropertyType.image.sourceUrl
            }
            link={
              lang === "EN"
                ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
            }
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      case "retail":
        developmentItems[4] = (
          <Card
            key={i}
            variant="overlay"
            title={
              lang === "EN"
                ? propertyTypes[i].name
                : propertyTypes[i]?.translation?.name || propertyTypes[i].name
            }
            titleClassName="h5 text-truncate"
            text={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.excerpt
                : propertyTypes[i].translation?.acfPropertyType?.excerpt ||
                  propertyTypes[i].acfPropertyType.excerpt
            }
            textClassName="font-size-sm"
            img={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.image.sourceUrl
                : propertyTypes[i].translation?.acfPropertyType?.image
                    ?.sourceUrl ||
                  propertyTypes[i].acfPropertyType.image.sourceUrl
            }
            link={
              lang === "EN"
                ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
            }
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      case "hospitality":
        developmentItems[5] = (
          <Card
            key={i}
            variant="overlay"
            title={
              lang === "EN"
                ? propertyTypes[i].name
                : propertyTypes[i]?.translation?.name || propertyTypes[i].name
            }
            titleClassName="h5 text-truncate"
            text={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.excerpt
                : propertyTypes[i].translation?.acfPropertyType?.excerpt ||
                  propertyTypes[i].acfPropertyType.excerpt
            }
            textClassName="font-size-sm"
            img={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.image.sourceUrl
                : propertyTypes[i].translation?.acfPropertyType?.image
                    ?.sourceUrl ||
                  propertyTypes[i].acfPropertyType.image.sourceUrl
            }
            link={
              lang === "EN"
                ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
            }
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      case "commercial":
        developmentItems[6] = (
          <Card
            key={i}
            variant="overlay"
            title={
              lang === "EN"
                ? propertyTypes[i].name
                : propertyTypes[i]?.translation?.name || propertyTypes[i].name
            }
            titleClassName="h5 text-truncate"
            text={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.excerpt
                : propertyTypes[i].translation?.acfPropertyType?.excerpt ||
                  propertyTypes[i].acfPropertyType.excerpt
            }
            textClassName="font-size-sm"
            img={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.image.sourceUrl
                : propertyTypes[i].translation?.acfPropertyType?.image
                    ?.sourceUrl ||
                  propertyTypes[i].acfPropertyType.image.sourceUrl
            }
            link={
              lang === "EN"
                ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
            }
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      case "industrial":
        developmentItems[7] = (
          <Card
            key={i}
            variant="overlay"
            title={
              lang === "EN"
                ? propertyTypes[i].name
                : propertyTypes[i]?.translation?.name || propertyTypes[i].name
            }
            titleClassName="h5 text-truncate"
            text={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.excerpt
                : propertyTypes[i].translation?.acfPropertyType?.excerpt ||
                  propertyTypes[i].acfPropertyType.excerpt
            }
            textClassName="font-size-sm"
            img={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.image.sourceUrl
                : propertyTypes[i].translation?.acfPropertyType?.image
                    ?.sourceUrl ||
                  propertyTypes[i].acfPropertyType.image.sourceUrl
            }
            link={
              lang === "EN"
                ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
            }
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      case "kavling":
        developmentItems[8] = (
          <Card
            key={i}
            variant="overlay"
            title={
              lang === "EN"
                ? propertyTypes[i].name
                : propertyTypes[i]?.translation?.name || propertyTypes[i].name
            }
            titleClassName="h5 text-truncate"
            text={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.excerpt
                : propertyTypes[i].translation?.acfPropertyType?.excerpt ||
                  propertyTypes[i].acfPropertyType.excerpt
            }
            textClassName="font-size-sm"
            img={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.image.sourceUrl
                : propertyTypes[i].translation?.acfPropertyType?.image
                    ?.sourceUrl ||
                  propertyTypes[i].acfPropertyType.image.sourceUrl
            }
            link={
              lang === "EN"
                ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
            }
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      default:
        break
    }
  }

  useLayoutEffect(() => {
    // load SAP cdn at header
    ;(function loadSAPCdn() {
      var script = document.createElement("script")
      script.src = `https://cdns.gigya.com/js/gigya.js?apikey=${sapKey}`
      document.getElementsByTagName("script")[0].parentNode.appendChild(script)
    })()
  }, [])

  useEffect(() => {
    function getAccountInfoResponse(response) {
      if (response.errorCode == 0) {
        var profile = response["profile"]
        var msg = "Hi," + profile["lastName"] + " Welcome back"
        // $("#lblName").text(msg);
        window.gigya.accounts.showScreenSet({
          screenSet: "SML-ProfileUpdate",
          startScreen: "gigya-update-profile-screen",
          containerID: "mainProfile",
        })
        setLoggedIn(true)
        // $("#btnLogin").hide(); // convert to vanilla javascript
        // $("#btnRegister").hide(); // convert to vanilla javascript
      } else {
        //console.log('Error :' + response.errorMessage);
        //$("#lblName").text(response.statusMessage);
      }
      //console.log(response);
    }

    setTimeout(() => {
      window.gigya.accounts.getAccountInfo({
        callback: getAccountInfoResponse,
      })
      window.gigya.accounts.addEventHandlers({
        onLogin: eventData => {
          setUser(eventData.profile)
          navigate("/profile/")
        },
      })
    }, 5000)
  }, [])

  useEffect(() => {
    setLoggedIn(user !== null)
  }, [user])

  const logoutResponse = response => {
    if (response.errorCode == 0) {
      navigate("/login/")
      setUser(null)
    } else {
      alert("Error :" + response.errorMessage)
    }
  }

  const handlerLogout = () => {
    window.gigya.accounts.logout({
      callback: logoutResponse,
    })
  }

  // load SAP cdn at header
  // (function loadSAPCdn() {
  //   var script = document.createElement('script');
  //   script.src = `https://cdns.gigya.com/js/gigya.js?apikey=${sapKey}`;
  //   document.getElementsByTagName('script')[0].parentNode.appendChild(script);
  // })();

  return (
    <>
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      <div id="navbarDesktop" className="overflow-x-hidden">
        <nav
          id="desktopNav"
          className={`navbar navbar-nav-main ${navBackground} ${
            navbarSearch.clicked || developmentToggle.clicked || corporateToggle.clicked || investorToggle.clicked ? "is-scrolled" : ""
          } fixed-top navbar-expand-lg d-none d-lg-flex`}
        >
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img
                className="logo-main"
                src={brandLogoMain}
                alt={brandLogoAlt}
              />
              <img
                className="logo-secondary"
                src={brandLogoSecondary}
                alt={brandLogoAlt}
              />
            </Link>
            <div className="navbar-collapse">
              <ul className="navbar-nav navbar-nav-main ml-auto">
                {loggedIn ? (
                  <>
                    <li className={`nav-item nav-desktop`}>
                      <span className="nav-link">
                        {`Hi ${user?.lastName},  Welcome back`}
                      </span>
                    </li>
                    <li className={`nav-item nav-desktop`}>
                      <Link className="nav-link" to="/profile">
                        Profile
                      </Link>
                    </li>
                    <li className={`nav-item nav-desktop`}>
                      <span
                        className="nav-link"
                        onClick={() => handlerLogout()}
                      >
                        Logout
                      </span>
                    </li>
                  </>
                ) : (
                  <>
                    {!navbarSearch.clicked && (
                      <>
                        <li className={`nav-item nav-desktop`}>
                          <Link className="nav-link" to="/">
                            Home
                          </Link>
                        </li>
                        <li className={`nav-item nav-desktop ${developmentToggle.clicked ? `active` : ``}`} data-target={`#Development`} onClick={handleDevelopmentClick}>
                          <a className="nav-link">
                            Development
                          </a>
                        </li>
                        <li className={`nav-item nav-desktop ${corporateToggle.clicked ? `active` : ``}`} data-target={`#Corporate`} onClick={handleCorporateClick}>
                          <a className="nav-link">
                            Corporate
                          </a>
                        </li>
                        <li className={`nav-item nav-desktop`}>
                          <Link className="nav-link" to="/sustainability/">
                            Sustainability
                          </Link>
                        </li>
                        <li className={`nav-item nav-desktop ${investorToggle.clicked ? `active` : ``}`} data-target={`#Investors`} onClick={handleInvestorClick}>
                          <a className="nav-link">
                            Investor
                          </a>
                        </li>
                      </>
                    )}
                    <li className="nav-item nav-item-search position-relative">
                      <a className="nav-link nav-link-icon">
                        <i
                          className={
                            navbarSearch.clicked ? "fal fa-times" : "fal fa-search"
                          }
                          onClick={handleClick}
                        ></i>
                      </a>
                      <form
                        action={`${lang === "EN" ? "" : "/id"}/search-result`}
                        className={
                          navbarSearch.clicked ? "form-search is-open" : "form-search"
                        }
                        target="_top"
                      >
                        <input
                          type="text"
                          name="key"
                          id="key"
                          className="form-control"
                          placeholder="Search"
                        />
                      </form>
                    </li>
                    <li className={`nav-item nav-desktop ml-3`}>
                      <Link 
                        className="btn btn-primary nav-primary pb-0 pt-1 register" 
                        to="/register/"
                      >
                        Register
                      </Link>
                    </li>
                    <li className={`nav-item nav-desktop ml-3`}>
                      <Link 
                        className="btn btn-primary nav-primary pb-0 pt-1 subscribe" 
                        to="/subscribe/"
                      >
                        Subscribe
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </nav>

        {/* development dropdown toggle */}
        <div
          id={menuItems[1].label}
          className={`megamenu-desktop d-none d-lg-block ${lang === "EN" && developmentToggle.clicked ? "show" : ""}`}
          data-content
        >
          <div className="container menu-area">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <span className="nav-link">
                    {lang === "EN" ? "Development" : "Pembangunan"}
                  </span>
                </li>
              </ul>
              <div className="d-flex">
                <a
                  href="https://ecatalog.sinarmasland.com?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_e-Catalog_2021&utm_content=ECatalog_SML_Website_Menu"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-link fx-underline mr-4"
                >
                  e-Catalog
                </a>
                <Link
                  to={lang === "EN" ? "/development/" : "/id/development/"}
                  className="btn btn-link fx-underline"
                >
                  {lang === "EN" ? "See All Projects" : "Lihat Semua Proyek"}
                </Link>
              </div>
            </div>
          </div>

          <div id="navSlider" className="container">
            <CardSlider noGutter>{developmentItems}</CardSlider>
          </div>
        </div>

        {/* corporate dropdown toggle */}
        <div
          id={menuItems[2].label}
          className={`megamenu-desktop megamenu-corporate d-none d-lg-block ${lang === "EN" && corporateToggle.clicked ? "show" : ""}`}
          data-content
        >
          <div className="container">
            <div className="row row-5">
              <div className="col-5 menu-area border-right">
                <ul className="navbar-nav mb-3">
                  <li className="nav-item active">
                    <span className="nav-link">
                      {menuItems[2].childItems.nodes[0].label}
                    </span>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={lang === "EN" ? "/partner/" : "/id/partner/"}
                      className="nav-link"
                    >
                      {menuItems[2].childItems.nodes[1].label}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={lang === "EN" ? "/news/" : "/id/news/"}
                      className="nav-link"
                    >
                      {menuItems[2].childItems.nodes[2].label}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://events.sinarmasland.com/"
                      className="nav-link"
                    >
                      {menuItems[2].childItems.nodes[3].label}
                    </a>
                  </li>
                </ul>
                <Link
                  to={lang === "EN" ? "/career/" : "/id/career/"}
                  className="btn btn-outline-dark mb-3"
                >
                  <span>{menuItems[2].childItems.nodes[4].label}</span>
                </Link>
                <a
                  href="https://bigtrons.sinarmasland.com/Register"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-outline-dark"
                >
                  <span>{menuItems[2].childItems.nodes[5].label}</span>
                </a>
              </div>
              <div className="col-7 submenu-area">
                <ul className={`navbar-nav ${lang === "EN" ? "" : "d-none"}`}>
                  {corporateItems &&
                    corporateItems[0] &&
                    corporateItems[0].childItems.nodes.map((item, i) => {
                      return (
                        <div key={`submenu-${item.label}`} className="nav-item">
                          <Link
                            to={lang === "EN" ? `${item.url}/` : `/id${item.url}/`}
                            className="nav-link nav-link-corporate"
                            // onClick={navClick}
                          >
                            {item.label}
                          </Link>
                        </div>
                      )
                    })}
                </ul>
                <ul className={`navbar-nav ${lang === "EN" ? "d-none" : ""}`}>
                  {corporateItemsId &&
                    corporateItemsId[0] &&
                    corporateItemsId[0].childItems.nodes.map((item, i) => {
                      return (
                        <div key={`submenu-${item.url}`} className="nav-item">
                          <Link
                            to={lang === "EN" ? `${item.url}/` : `/id${item.url}/`}
                            className="nav-link nav-link-corporate"
                            // onClick={navClick}
                          >
                            {item.label}
                          </Link>
                        </div>
                      )
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* investor dropdown toggle */}
        <div
          id={"Investors"}
          className={`megamenu-desktop d-none d-block ${lang === "EN" && investorToggle.clicked ? "show" : ""}`}
          data-content
        >
          <div className="container">
            <div className="row">
              <div className="col-6 menu-area">
                <ul className="navbar-nav">
                  {investorsItems &&
                    investorsItems.map((item, i) => {
                      if (i < 5) {
                        return (
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                item.url === "/general-information"
                                  ? lang === "EN"
                                    ? `/investors/`
                                    : "/id/investors/"
                                  : (item.url === "/about-us/board-of-directors" || item.url === "/living-lab-ventures")
                                  ? lang === "EN"
                                    ? `${item.url}/`
                                    : `/id${item.url}/`
                                  : lang === "EN"
                                  ? `${parseLink(item.url)}/`
                                  : `/id${parseLink(item.url)}/`
                              }
                            >
                              {item.label}
                            </Link>
                          </li>
                        )
                      }
                    })}
                </ul>
              </div>
              <div className="col-6 menu-area">
                <ul className="navbar-nav">
                  {investorsItems &&
                    investorsItems.map((item, i) => {
                      if (i >= 5) {
                        return (
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                item.url === "/general-information"
                                  ? lang === "EN"
                                    ? `/investors/`
                                    : "/id/investors/"
                                  : (item.url === "/about-us/board-of-directors" || item.url === "/living-lab-ventures")
                                  ? lang === "EN"
                                    ? `${item.url}/`
                                    : `/id${item.url}/`
                                  : lang === "EN"
                                  ? `${parseLink(item.url)}/`
                                  : `/id${parseLink(item.url)}/`
                              }
                            >
                              {item.label}
                            </Link>
                          </li>
                        )
                      }
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NavbarDesktop
