import React from "react"

import Slider from "react-slick"

export const CardSlider = ({
  forwardKey,
  forwardRef,
  asNavFor,
  infinite,
  fade,
  noGutter,
  visibleInitial,
  visibleLgDown,
  visibleMdDown,
  visibleSmDown,
  dotsInitial,
  dotsClass,
  dotsLgDown,
  dotsMdDown,
  dotsSmDown,
  arrowsInitial,
  arrowsLgDown,
  arrowsMdDown,
  arrowsSmDown,
  showInitial,
  showLgDown,
  showMdDown,
  showSmDown,
  scrollInitial,
  scrollLgDown,
  scrollMdDown,
  scrollSmDown,
  className,
  children,
}) => {
  const settings = {
    key: forwardKey,
    ref: forwardRef,
    className: `card-slider ${className} 
      ${noGutter && "slick-px-0"}
      ${visibleInitial ? "show-initial" : ""} 
      ${visibleLgDown ? "show-lg-down" : ""} 
      ${visibleMdDown ? "show-md-down" : ""} 
      ${visibleSmDown ? "show-sm-down" : ""}`,
    asNavFor: asNavFor,
    infinite: infinite,
    fade: fade,
    dots: dotsInitial,
    dotsClass: dotsClass,
    arrows: arrowsInitial,
    slidesToShow: showInitial,
    slidesToScroll: scrollInitial,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          dots: dotsLgDown,
          arrows: arrowsLgDown,
          slidesToShow: showLgDown,
          slidesToScroll: scrollLgDown,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: dotsMdDown,
          arrows: arrowsMdDown,
          slidesToShow: showMdDown,
          slidesToScroll: scrollMdDown,
        },
      },

      {
        breakpoint: 575,
        settings: {
          dots: dotsSmDown,
          arrows: arrowsSmDown,
          slidesToShow: showSmDown,
          slidesToScroll: scrollSmDown,
        },
      },
    ],
  }
  return <Slider {...settings}>{children}</Slider>
}

CardSlider.defaultProps = {
  infinite: false,
  fade: false,
  noGutter: false,
  visibleInitial: true,
  visibleLgDown: true,
  visibleMdDown: true,
  visibleSmDown: true,
  dotsInitial: false,
  dotsLgDown: false,
  dotsMdDown: false,
  dotsSmDown: false,
  arrowsInitial: true,
  arrowsLgDown: true,
  arrowsMdDown: false,
  arrowsSmDown: false,
  showInitial: 4,
  showLgDown: 4,
  showMdDown: 2,
  showSmDown: 1,
  scrollInitial: 1,
  scrollLgDown: 1,
  scrollMdDown: 1,
  scrollSmDown: 1,
  className: "",
  dotsClass: "",
}
