import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share"
import { LangContext } from "../../context/langContext"

export const SocmedLinks = ({
  facebookLink,
  twitterLink,
  linkedinLink,
  copyLink,
  className,
  isAmp,
  location,
  onClick,
}) => {
  const { lang, setLang } = useContext(LangContext)
  const langKey = (location?.pathname?.split('/')[1] || location?.uri?.split('/')[1]) === "id" ? "ID" : lang === "ID" ? "ID" : "EN"
  useEffect(() => { setLang(langKey) }, [langKey])
  return (
    <>
      <div className={`socmed-links ${className}`}>
        {isAmp ? (
          <>
            <span className="share-caption mr-3">{langKey === "EN" ? "Share:" : "Bagikan:"}</span>
            {facebookLink && (
              <div className="icon-place">
                <amp-social-share
                  type="facebook"
                  aria-label="Share on Facebook"
                  width="21"
                  height="21"      
                  data-param-app_id={process.env.FB_APP_ID}    
                ></amp-social-share>
              </div>
            )}
            {twitterLink && (
              <div className="icon-place">
                <amp-social-share
                  type="twitter"
                  aria-label="Share on Twitter"
                  width="21"
                  height="21"          
                ></amp-social-share>
              </div>
            )}
            {linkedinLink && (
              <div className="icon-place">
                <amp-social-share
                  type="linkedin"
                  aria-label="Share on Linkedin"
                  width="21"
                  height="21"          
                ></amp-social-share>
              </div>
            )}
            {/* <div className="icon-place">
              <amp-social-share type="system"
                aria-label="Share Button"
                width="12"
                height="14"
              ></amp-social-share>
            </div> */}
          </>
        ) : (
          <>
            <span className="share-caption mr-3" style={{color: "#ab0204", verticalAlign: "middle", display: "inline-block", paddingBottom: "10px"}}>{lang === "EN" ? "Share:" : "Bagikan:"}</span>
            {facebookLink && (
              <FacebookShareButton url={facebookLink} className="socmed-links">
                <i className="fab fa-facebook-square"></i>
              </FacebookShareButton>
            )}
            {twitterLink && (
              <TwitterShareButton url={twitterLink} className="socmed-links">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" className="svg-twitter-icon">
                  <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
                </svg>
              </TwitterShareButton>
            )}
            {linkedinLink && (
              <LinkedinShareButton url={linkedinLink} className="socmed-links">
                <i className="fab fa-linkedin"></i>
              </LinkedinShareButton>
            )}
            {copyLink && (
              <a 
                id="copyButton"
                onClick={onClick} 
                className="btn btn-primary"
                href="#"
                aria-label="Copy Url"
              >
                <i className="fa fa-link"></i>
              </a>
            )}
          </>
        )}
      </div>
    </>
  )
}

SocmedLinks.propTypes = {
  facebookLink: PropTypes.string,
  twitterLink: PropTypes.string,
  linkedinLink: PropTypes.string,
  copyLink: PropTypes.string,
  className: PropTypes.string,
  isAmp: PropTypes.bool,
}
