import React from "react"
import PropTypes from "prop-types"

// Container
export const Container = ({ id, className, children }) => {
  return (
    <div id={id} className={`container ${className}`}>
      {children}
    </div>
  )
}

Container.propTypes = {
  id: PropTypes.string, // Set container optional id
  className: PropTypes.string, // Set container optional classes
  children: PropTypes.any,
}

Container.defaultProps = {
  className: "",
}

// Section
export const Section = ({ id, className, children, ...otherProps }) => {
  return (
    <section id={id} className={`py-main ${className}`} {...otherProps}>
      {children}
    </section>
  )
}

Section.propTypes = {
  id: PropTypes.string, // Set section optional id
  className: PropTypes.string, // Set section optional classes
  children: PropTypes.any,
}

Section.defaultProps = {
  className: "",
  id: "",
}
