import React, { useEffect, useState, useLayoutEffect } from "react"
import { navigate } from "gatsby"
import Lottie from "react-lottie"

import { CardNavBottom, CardAmp } from "../card/card"
import { Link } from "../utils/utils"

import burgerMenu from "../../assets/lotties/burger_menu_click.json"
import closeMenu from "../../assets/lotties/burger_close_click.json"
import { Container } from "../grid/grid"
import backsound from "../../assets/audio/smart-move-jingle.mp3"
// import { LangContext } from "../../context/langContext"
// import { Button } from "../buttons/buttons"

const NavbarMobile = ({
  brandLogoMain,
  brandLogoSecondary,
  brandLogoAlt,
  url,
  navBackground,
  fetchedData,
  lang = "EN",
  newsLangSlug,
  isDd,
  isAmp,
  isApartemen = false,
  isWvc = false,
  soundFile = backsound,
}) => {
  // const { lang: langContext } = useContext(LangContext)
  const [toggleMobile, setToggleMobile] = useState(false)
  const [soundToggle, setSoundToggle] = useState({
    clicked: false,
  })
  const [audio] = useState(typeof Audio !== "undefined" && new Audio(soundFile))

  const handleSoundClick = () => {
    if (soundToggle.clicked === false) {
      setSoundToggle({ clicked: true })
      audio.play()
      audio.loop = true
    } else {
      setSoundToggle({ clicked: false })
      audio.pause()
    }
  }

  const menuItems =
    lang === "EN"
      ? fetchedData.menus.nodes[2].menuItems.nodes
      : fetchedData.menus.nodes[3].menuItems.nodes
  const propertyTypes = fetchedData.propertyTypes.nodes

  let menuItemsWPrecious = []
  const preciousMenuMobile = {
    url: "/precious",
    label: "Precious",
    childItems: { nodes: [] },
  }

  menuItems.map(item => menuItemsWPrecious.push(item))

  // menuItemsWPrecious.splice(6, 0, preciousMenuMobile)

  const mobileToggler = {
    loop: false,
    autoplay: true,
    animationData: toggleMobile ? burgerMenu : closeMenu,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const showMenu = toggleMobile ? "show" : ""

  let items = []

  // useLayoutEffect(() => {
  //   // load AMP cdn at header
  //   ;(function loadSAPCdn() {
  //     var script = document.createElement("script")
  //     script.src = `https://cdn.ampproject.org/v0.js`
  //     document.getElementsByTagName("script")[0].parentNode.appendChild(script)
  //   })()
  // }, [])

  for (let i = propertyTypes.length - 1; i >= 0; i--) {
    switch (propertyTypes[i].slug) {
      case "township":
        items[0] = (
          <li key={`slider-${propertyTypes[i].id}`} className="glide__slide">
            {isAmp ? (
              <CardAmp
                variant="overlay"
                title={
                  lang === "EN"
                    ? propertyTypes[i].name
                    : propertyTypes[i].translation?.name ||
                      propertyTypes[i].name
                }
                titleClassName="h5"
                text={
                  lang === "EN"
                    ? `${propertyTypes[i]?.description?.substr(0, 100)}...`
                    : `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...` !== "undefined..."
                    ? `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...`
                    : `${propertyTypes[i]?.description?.substr(0, 100)}...`
                }
                textClassName="font-size-sm"
                img={
                  lang === "EN"
                    ? propertyTypes[i].acfPropertyType.image.sourceUrl
                    : propertyTypes[i]?.translation?.acfPropertyType?.image
                        ?.sourceUrl ||
                      propertyTypes[i]?.acfPropertyType?.image?.sourceUrl
                }
                link={
                  lang === "EN"
                    ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                    : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
                }
                imgHeight="h-150px card-bg-img-top"
                className="mb-3 card-nav"
                imgOverlay="bottom"
              />
            ) : (
              <CardNavBottom
                variant="overlay"
                title={
                  lang === "EN"
                    ? propertyTypes[i].name
                    : propertyTypes[i].translation?.name ||
                      propertyTypes[i].name
                }
                titleClassName="h5"
                text={
                  lang === "EN"
                    ? `${propertyTypes[i]?.description?.substr(0, 100)}...`
                    : `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...` !== "undefined..."
                    ? `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...`
                    : `${propertyTypes[i]?.description?.substr(0, 100)}...`
                }
                textClassName="font-size-sm"
                img={
                  lang === "EN"
                    ? propertyTypes[i].acfPropertyType.image.sourceUrl
                    : propertyTypes[i]?.translation?.acfPropertyType?.image
                        ?.sourceUrl ||
                      propertyTypes[i]?.acfPropertyType?.image?.sourceUrl
                }
                link={
                  lang === "EN"
                    ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                    : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
                }
                imgHeight="h-150px card-bg-img-top d-flex"
                className="mb-3 card-nav"
                imgOverlay="bottom"
              />
            )}
          </li>
        )
        break
      case "residential":
        items[1] = (
          <li key={`slider-${propertyTypes[i].id}`} className="glide__slide">
            {isAmp ? (
              <CardAmp
                variant="overlay"
                title={
                  lang === "EN"
                    ? propertyTypes[i].name
                    : propertyTypes[i].translation?.name ||
                      propertyTypes[i].name
                }
                titleClassName="h5 text-truncate"
                text={
                  lang === "EN"
                    ? `${propertyTypes[i]?.description?.substr(0, 100)}...`
                    : `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...` !== "undefined..."
                    ? `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...`
                    : `${propertyTypes[i]?.description?.substr(0, 100)}...`
                }
                textClassName="font-size-sm"
                img={
                  lang === "EN"
                    ? propertyTypes[i].acfPropertyType.image.sourceUrl
                    : propertyTypes[i]?.translation?.acfPropertyType?.image
                        ?.sourceUrl ||
                      propertyTypes[i]?.acfPropertyType?.image?.sourceUrl
                }
                link={
                  lang === "EN"
                    ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                    : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
                }
                imgHeight="h-150px"
                className="mb-3 card-nav"
                imgOverlay="bottom"
              />
            ) : (
              <CardNavBottom
                variant="overlay"
                title={
                  lang === "EN"
                    ? propertyTypes[i].name
                    : propertyTypes[i].translation?.name ||
                      propertyTypes[i].name
                }
                titleClassName="h5 text-truncate"
                text={
                  lang === "EN"
                    ? `${propertyTypes[i]?.description?.substr(0, 100)}...`
                    : `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...` !== "undefined..."
                    ? `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...`
                    : `${propertyTypes[i]?.description?.substr(0, 100)}...`
                }
                textClassName="font-size-sm"
                img={
                  lang === "EN"
                    ? propertyTypes[i].acfPropertyType.image.sourceUrl
                    : propertyTypes[i]?.translation?.acfPropertyType?.image
                        ?.sourceUrl ||
                      propertyTypes[i]?.acfPropertyType?.image?.sourceUrl
                }
                link={
                  lang === "EN"
                    ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                    : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
                }
                imgHeight="h-150px d-flex"
                className="mb-3 card-nav"
                imgOverlay="bottom"
              />
            )}
          </li>
        )
        break
      case "office":
        items[2] = (
          <li key={`slider-${propertyTypes[i].id}`} className="glide__slide">
            {isAmp ? (
              <CardAmp
                variant="overlay"
                title={
                  lang === "EN"
                    ? propertyTypes[i].name
                    : propertyTypes[i].translation?.name ||
                      propertyTypes[i].name
                }
                titleClassName="h5 text-truncate"
                text={
                  lang === "EN"
                    ? `${propertyTypes[i]?.description?.substr(0, 100)}...`
                    : `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...` !== "undefined..."
                    ? `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...`
                    : `${propertyTypes[i]?.description?.substr(0, 100)}...`
                }
                textClassName="font-size-sm"
                img={
                  lang === "EN"
                    ? propertyTypes[i].acfPropertyType.image.sourceUrl
                    : propertyTypes[i]?.translation?.acfPropertyType?.image
                        ?.sourceUrl ||
                      propertyTypes[i]?.acfPropertyType?.image?.sourceUrl
                }
                link={
                  lang === "EN"
                    ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                    : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
                }
                imgHeight="h-150px"
                className="mb-3 card-nav"
                imgOverlay="bottom"
              />
            ) : (
              <CardNavBottom
                variant="overlay"
                title={
                  lang === "EN"
                    ? propertyTypes[i].name
                    : propertyTypes[i].translation?.name ||
                      propertyTypes[i].name
                }
                titleClassName="h5 text-truncate"
                text={
                  lang === "EN"
                    ? `${propertyTypes[i]?.description?.substr(0, 100)}...`
                    : `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...` !== "undefined..."
                    ? `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...`
                    : `${propertyTypes[i]?.description?.substr(0, 100)}...`
                }
                textClassName="font-size-sm"
                img={
                  lang === "EN"
                    ? propertyTypes[i].acfPropertyType.image.sourceUrl
                    : propertyTypes[i]?.translation?.acfPropertyType?.image
                        ?.sourceUrl ||
                      propertyTypes[i]?.acfPropertyType?.image?.sourceUrl
                }
                link={
                  lang === "EN"
                    ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                    : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
                }
                imgHeight="h-150px d-flex"
                className="mb-3 card-nav"
                imgOverlay="bottom"
              />
            )}
          </li>
        )
        break
      case "retail":
        items[3] = (
          <li key={`slider-${propertyTypes[i].id}`} className="glide__slide">
            {isAmp ? (
              <CardAmp
                variant="overlay"
                title={
                  lang === "EN"
                    ? propertyTypes[i].name
                    : propertyTypes[i].translation?.name ||
                      propertyTypes[i].name
                }
                titleClassName="h5 text-truncate"
                text={
                  lang === "EN"
                    ? `${propertyTypes[i]?.description?.substr(0, 100)}...`
                    : `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...` !== "undefined..."
                    ? `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...`
                    : `${propertyTypes[i]?.description?.substr(0, 100)}...`
                }
                textClassName="font-size-sm"
                img={
                  lang === "EN"
                    ? propertyTypes[i].acfPropertyType.image.sourceUrl
                    : propertyTypes[i]?.translation?.acfPropertyType?.image
                        ?.sourceUrl ||
                      propertyTypes[i]?.acfPropertyType?.image?.sourceUrl
                }
                link={
                  lang === "EN"
                    ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                    : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
                }
                imgHeight="h-150px"
                className="mb-3 card-nav"
                imgOverlay="bottom"
              />
            ) : (
              <CardNavBottom
                variant="overlay"
                title={
                  lang === "EN"
                    ? propertyTypes[i].name
                    : propertyTypes[i].translation?.name ||
                      propertyTypes[i].name
                }
                titleClassName="h5 text-truncate"
                text={
                  lang === "EN"
                    ? `${propertyTypes[i]?.description?.substr(0, 100)}...`
                    : `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...` !== "undefined..."
                    ? `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...`
                    : `${propertyTypes[i]?.description?.substr(0, 100)}...`
                }
                textClassName="font-size-sm"
                img={
                  lang === "EN"
                    ? propertyTypes[i].acfPropertyType.image.sourceUrl
                    : propertyTypes[i]?.translation?.acfPropertyType?.image
                        ?.sourceUrl ||
                      propertyTypes[i]?.acfPropertyType?.image?.sourceUrl
                }
                link={
                  lang === "EN"
                    ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                    : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
                }
                imgHeight="h-150px d-flex"
                className="mb-3 card-nav"
                imgOverlay="bottom"
              />
            )}
          </li>
        )
        break
      case "hospitality":
        items[4] = (
          <li key={`slider-${propertyTypes[i].id}`} className="glide__slide">
            {isAmp ? (
              <CardAmp
                variant="overlay"
                title={
                  lang === "EN"
                    ? propertyTypes[i].name
                    : propertyTypes[i].translation?.name ||
                      propertyTypes[i].name
                }
                titleClassName="h5 text-truncate"
                text={
                  lang === "EN"
                    ? `${propertyTypes[i]?.description?.substr(0, 100)}...`
                    : `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...` !== "undefined..."
                    ? `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...`
                    : `${propertyTypes[i]?.description?.substr(0, 100)}...`
                }
                textClassName="font-size-sm"
                img={
                  lang === "EN"
                    ? propertyTypes[i].acfPropertyType.image.sourceUrl
                    : propertyTypes[i]?.translation?.acfPropertyType?.image
                        ?.sourceUrl ||
                      propertyTypes[i]?.acfPropertyType?.image?.sourceUrl
                }
                link={
                  lang === "EN"
                    ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                    : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
                }
                imgHeight="h-150px"
                className="mb-3 card-nav"
                imgOverlay="bottom"
              />
            ) : (
              <CardNavBottom
                variant="overlay"
                title={
                  lang === "EN"
                    ? propertyTypes[i].name
                    : propertyTypes[i].translation?.name ||
                      propertyTypes[i].name
                }
                titleClassName="h5 text-truncate"
                text={
                  lang === "EN"
                    ? `${propertyTypes[i]?.description?.substr(0, 100)}...`
                    : `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...` !== "undefined..."
                    ? `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...`
                    : `${propertyTypes[i]?.description?.substr(0, 100)}...`
                }
                textClassName="font-size-sm"
                img={
                  lang === "EN"
                    ? propertyTypes[i].acfPropertyType.image.sourceUrl
                    : propertyTypes[i]?.translation?.acfPropertyType?.image
                        ?.sourceUrl ||
                      propertyTypes[i]?.acfPropertyType?.image?.sourceUrl
                }
                link={
                  lang === "EN"
                    ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                    : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
                }
                imgHeight="h-150px d-flex"
                className="mb-3 card-nav"
                imgOverlay="bottom"
              />
            )}
          </li>
        )
        break
      case "commercial":
        items[5] = (
          <li key={`slider-${propertyTypes[i].id}`} className="glide__slide">
            {isAmp ? (
              <CardAmp
                variant="overlay"
                title={
                  lang === "EN"
                    ? propertyTypes[i].name
                    : propertyTypes[i].translation?.name ||
                      propertyTypes[i].name
                }
                titleClassName="h5 text-truncate"
                text={
                  lang === "EN"
                    ? `${propertyTypes[i]?.description?.substr(0, 100)}...`
                    : `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...` !== "undefined..."
                    ? `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...`
                    : `${propertyTypes[i]?.description?.substr(0, 100)}...`
                }
                textClassName="font-size-sm"
                img={
                  lang === "EN"
                    ? propertyTypes[i].acfPropertyType.image.sourceUrl
                    : propertyTypes[i]?.translation?.acfPropertyType?.image
                        ?.sourceUrl ||
                      propertyTypes[i]?.acfPropertyType?.image?.sourceUrl
                }
                link={
                  lang === "EN"
                    ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                    : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
                }
                imgHeight="h-150px card-bg-img-top"
                className="mb-3 card-nav"
                imgOverlay="bottom"
              />
            ) : (
              <CardNavBottom
                variant="overlay"
                title={
                  lang === "EN"
                    ? propertyTypes[i].name
                    : propertyTypes[i].translation?.name ||
                      propertyTypes[i].name
                }
                titleClassName="h5 text-truncate"
                text={
                  lang === "EN"
                    ? `${propertyTypes[i]?.description?.substr(0, 100)}...`
                    : `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...` !== "undefined..."
                    ? `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...`
                    : `${propertyTypes[i]?.description?.substr(0, 100)}...`
                }
                textClassName="font-size-sm"
                img={
                  lang === "EN"
                    ? propertyTypes[i].acfPropertyType.image.sourceUrl
                    : propertyTypes[i]?.translation?.acfPropertyType?.image
                        ?.sourceUrl ||
                      propertyTypes[i]?.acfPropertyType?.image?.sourceUrl
                }
                link={
                  lang === "EN"
                    ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                    : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
                }
                imgHeight="h-150px card-bg-img-top d-flex"
                className="mb-3 card-nav"
                imgOverlay="bottom"
              />
            )}
          </li>
        )
        break
      case "industrial":
        items[6] = (
          <li key={`slider-${propertyTypes[i].id}`} className="glide__slide">
            {isAmp ? (
              <CardAmp
                variant="overlay"
                title={
                  lang === "EN"
                    ? propertyTypes[i].name
                    : propertyTypes[i].translation?.name ||
                      propertyTypes[i].name
                }
                titleClassName="h5 text-truncate"
                text={
                  lang === "EN"
                    ? `${propertyTypes[i]?.description?.substr(0, 100)}...`
                    : `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...` !== "undefined..."
                    ? `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...`
                    : `${propertyTypes[i]?.description?.substr(0, 100)}...`
                }
                textClassName="font-size-sm"
                img={
                  lang === "EN"
                    ? propertyTypes[i].acfPropertyType.image.sourceUrl
                    : propertyTypes[i]?.translation?.acfPropertyType?.image
                        ?.sourceUrl ||
                      propertyTypes[i]?.acfPropertyType?.image?.sourceUrl
                }
                link={
                  lang === "EN"
                    ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                    : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
                }
                imgHeight="h-150px"
                className="mb-3 card-nav"
                imgOverlay="bottom"
              />
            ) : (
              <CardNavBottom
                variant="overlay"
                title={
                  lang === "EN"
                    ? propertyTypes[i].name
                    : propertyTypes[i].translation?.name ||
                      propertyTypes[i].name
                }
                titleClassName="h5 text-truncate"
                text={
                  lang === "EN"
                    ? `${propertyTypes[i]?.description?.substr(0, 100)}...`
                    : `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...` !== "undefined..."
                    ? `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...`
                    : `${propertyTypes[i]?.description?.substr(0, 100)}...`
                }
                textClassName="font-size-sm"
                img={
                  lang === "EN"
                    ? propertyTypes[i].acfPropertyType.image.sourceUrl
                    : propertyTypes[i]?.translation?.acfPropertyType?.image
                        ?.sourceUrl ||
                      propertyTypes[i]?.acfPropertyType?.image?.sourceUrl
                }
                link={
                  lang === "EN"
                    ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                    : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
                }
                imgHeight="h-150px d-flex"
                className="mb-3 card-nav"
                imgOverlay="bottom"
              />
            )}
          </li>
        )
        break
      case "kavling":
        items[7] = (
          <li key={`slider-${propertyTypes[i].id}`} className="glide__slide">
            {isAmp ? (
              <CardAmp
                variant="overlay"
                title={
                  lang === "EN"
                    ? propertyTypes[i].name
                    : propertyTypes[i].translation?.name ||
                      propertyTypes[i].name
                }
                titleClassName="h5 text-truncate"
                text={
                  lang === "EN"
                    ? `${propertyTypes[i]?.description?.substr(0, 100)}...`
                    : `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...` !== "undefined..."
                    ? `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...`
                    : `${propertyTypes[i]?.description?.substr(0, 100)}...`
                }
                textClassName="font-size-sm"
                img={
                  lang === "EN"
                    ? propertyTypes[i].acfPropertyType.image.sourceUrl
                    : propertyTypes[i]?.translation?.acfPropertyType?.image
                        ?.sourceUrl ||
                      propertyTypes[i]?.acfPropertyType?.image?.sourceUrl
                }
                link={
                  lang === "EN"
                    ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                    : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
                }
                imgHeight="h-150px"
                className="mb-3 card-nav"
                imgOverlay="bottom"
              />
            ) : (
              <CardNavBottom
                variant="overlay"
                title={
                  lang === "EN"
                    ? propertyTypes[i].name
                    : propertyTypes[i].translation?.name ||
                      propertyTypes[i].name
                }
                titleClassName="h5 text-truncate"
                text={
                  lang === "EN"
                    ? `${propertyTypes[i]?.description?.substr(0, 100)}...`
                    : `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...` !== "undefined..."
                    ? `${propertyTypes[i]?.translation?.description?.substr(
                        0,
                        100
                      )}...`
                    : `${propertyTypes[i]?.description?.substr(0, 100)}...`
                }
                textClassName="font-size-sm"
                img={
                  lang === "EN"
                    ? propertyTypes[i].acfPropertyType.image.sourceUrl
                    : propertyTypes[i]?.translation?.acfPropertyType?.image
                        ?.sourceUrl ||
                      propertyTypes[i]?.acfPropertyType?.image?.sourceUrl
                }
                link={
                  lang === "EN"
                    ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                    : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
                }
                imgHeight="h-150px d-flex"
                className="mb-3 card-nav"
                imgOverlay="bottom"
              />
            )}
          </li>
        )
        break
      default:
        break
    }
  }

  const urlArr = url ? url.split("/") : null
  const toggleHandler = () => {
    const mainElmnt = document.querySelector("main")
    setToggleMobile(!toggleMobile)
    if (toggleMobile) {
      mainElmnt.classList.remove("overflow-hidden")
    } else if (!toggleMobile) {
      setTimeout(() => mainElmnt.classList.add("overflow-hidden"), 200)
    }
  }

  const setToID = async () => {
    if (newsLangSlug) {
      if (lang !== "ID") {
        window.location.href = `/id/news/${newsLangSlug}/`
      }
    } else {
      if (document.location.pathname.includes("/id")) {
        await navigate(document.location.pathname)
      } else {
        await navigate(`/id${document.location.pathname}`)
      }
    }
  }
  const setToEN = async () => {
    let pathname = document.location.pathname
    let enPath = pathname.split("/id").join("")

    if (newsLangSlug) {
      if (lang !== "EN") {
        window.location.href = `/news/${newsLangSlug}/`
      }
    } else {
      navigate(`${enPath}`)
    }
  }

  useEffect(() => {
    if (isDd) {
      document.getElementsByClassName(
        "logo-secondary"
      )[1].style.display = `none`
      document.getElementsByClassName("logo-main")[1].style.display = `block`
    }
  }, [])

  return (
    <div id="navbarMobile">
      {isAmp ? (
        <>
          <header>
            <amp-mega-menu height="60" layout="fixed-height">
              <nav
                className={`navbar navbar-nav-main ${navBackground} fixed-top`}
              >
                <ul>
                  {/* <li>
                  <span role="button"></span>
                  <div role="dialog">
                    <amp-img
                      src="/static/inline-examples/images/image1.jpg"
                      width="300"
                      height="200"
                    ></amp-img>
                  </div>
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleHandler}
                  >
                    <Lottie options={mobileToggler} width={30} height={30} />
                  </button>
                  <Link
                    className={`btn btn-primary btn-login ${
                      toggleMobile ? "d-none" : ""
                    }`}
                    to="/login"
                  >
                    Login
                  </Link>
                </li> */}
                  <li>
                    <Link
                      className="navbar-brand"
                      to={lang === "ID" ? "/id" : "/"}
                    >
                      <amp-img
                        class="logo-main img-fluid"
                        src={brandLogoMain}
                        alt={brandLogoAlt}
                        // layout="responsive"
                        width="185"
                        height="35"
                        id="scrollLogo"
                      ></amp-img>
                    </Link>
                  </li>
                  <li class="navbar-toggler">
                    <div
                      role="button"
                      on="tap:sidebar1.toggle"
                      tabindex="0"
                      class="hamburger humburger1"
                    >
                      &#x268C;
                    </div>
                  </li>
                  <li class="login-toggler">
                    <Link
                      className={`btn btn-primary btn-login ${
                        toggleMobile ? "d-none" : isWvc ? "d-none" : ""
                      }`}
                      to="/login/"
                    >
                      Login
                    </Link>
                  </li>
                </ul>
              </nav>
            </amp-mega-menu>
          </header>
          <amp-sidebar id="sidebar1" layout="nodisplay" side="right">
            <div
              role="button"
              aria-label="close sidebar"
              on="tap:sidebar1.toggle"
              tabindex="0"
              class="close-sidebar-right"
            >
              ✕
            </div>
            <ul class="sidebar">
              <li>
                <form
                  method="post"
                  class="form-control-search-wrapper"
                  action-xhr="/search-result"
                  target="_top"
                >
                  <input
                    type="text"
                    name="key"
                    class="form-control form-control-search"
                    placeholder="Search"
                    id="navMobileSearch"
                  />
                </form>
              </li>
              {menuItemsWPrecious.map((item, i) => {
                return i === 0 ? (
                  <li key={i}>
                    <a
                      href={
                        lang === "EN"
                          ? item.label.toLowerCase() === "home"
                            ? item.url
                            : `${item.url}/`
                          : item.label.toLowerCase() === "home"
                          ? `/id${item.url}`
                          : `/id${item.url}/`
                      }
                    >
                      {item.label}
                    </a>
                  </li>
                ) : item.childItems.nodes.length > 0 ||
                  item.label === "Development" ||
                  item.label === "Pembangunan" ||
                  item.label === "Corporate" ||
                  item.label === "Perusahaan" ||
                  item.label === "Investors" ||
                  item.label === "Investor" ? (
                  <li key={`mobilemenu-${item.label}`}>
                    <div
                      role="button"
                      on={`tap:side${item.label}.toggle`}
                      tabindex="0"
                      class="sub-sidebar"
                    >
                      {item.label}
                    </div>
                  </li>
                ) : (
                  <li key={i}>
                    <a
                      href={
                        lang === "EN"
                          ? item.label.toLowerCase() === "home"
                            ? item.url
                            : `${item.url}/`
                          : item.label.toLowerCase() === "home"
                          ? `/id${item.url}`
                          : `/id${item.url}/`
                      }
                    >
                      {item.label}
                    </a>
                  </li>
                )
              })}
              <li class="lang-toggler">
                <div
                  onClick={setToEN}
                  className={`mr-2 lang-item ${lang === "EN" ? "active" : ""}`}
                >
                  EN
                </div>
                <div className="mr-2">|</div>
                <div
                  onClick={setToID}
                  className={`lang-item ${lang === "ID" ? "active" : ""}`}
                >
                  ID
                </div>
              </li>
            </ul>
          </amp-sidebar>
          <amp-sidebar
            id={`side${menuItems[1]?.label}`}
            layout="nodisplay"
            side="right"
          >
            <div
              role="button"
              aria-label="close sidebar"
              on={`tap:side${menuItems[1]?.label}.toggle`}
              tabindex="0"
              class="close-sidebar-left"
            >
              &#x2190;
            </div>
            <ul class="sidebar">
              <li class="primary-link">
                <span> {lang === "EN" ? "Development" : "Pengembangan"}</span>
              </li>
              <li class="primary-link">
                <a href="https://ecatalog.sinarmasland.com?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_e-Catalog_2021&utm_content=ECatalog_SML_Website_Menu">
                  {" "}
                  e-Catalog
                </a>
              </li>
              <li class="primary-link">
                <a href={lang === "EN" ? "/development/" : "/id/development/"}>
                  {" "}
                  {lang === "EN" ? "See All Projects" : "Lihat Semua Proyek"}
                </a>
              </li>
              {items}
              {/* <li>
              <amp-img
                class="img-fluid"
                src={brandLogoMain}
                alt={brandLogoAlt}
                // layout="responsive"
                width="185"
                height="35"
              ></amp-img>
            </li> */}
            </ul>
          </amp-sidebar>
          <amp-sidebar
            id={`side${menuItems[2]?.label}`}
            layout="nodisplay"
            side="right"
          >
            <div
              role="button"
              aria-label="close sidebar"
              on={`tap:side${menuItems[2]?.label}.toggle`}
              tabindex="0"
              class="close-sidebar-left"
            >
              &#x2190;
            </div>
            <ul class="sidebar">
              <li>
                <span> {lang === "EN" ? "About" : "Tentang"}</span>
                <ul class="sub-about">
                  <li>
                    <a href="/about-us/"> About Us</a>
                  </li>
                  <li>
                    <a href="/about-us/history/"> History</a>
                  </li>
                  <li>
                    <a href="/about-us/awards/"> Awards</a>
                  </li>
                  <li>
                    <a href="/about-us/overseas-business/">
                      {" "}
                      Overseas Business
                    </a>
                  </li>
                  <li>
                    <a href="/contact-us/"> Contact Us</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href={lang === "EN" ? "/partner/" : "/id/partner/"}>
                  {lang === "EN" ? "Partners" : "Partner"}
                </a>
              </li>
              <li>
                <a href={lang === "EN" ? "/news/" : "/id/news/"}>
                  {lang === "EN" ? "News" : "Berita"}
                </a>
              </li>
              <li>
                <a href="#">{lang === "EN" ? "Events" : "Acara"}</a>
              </li>
              <li class="sub-menu-outline">
                <a href={lang === "EN" ? "/career/" : "/id/career/"}>
                  {lang === "EN" ? "Career" : "Karir"}
                </a>
              </li>
              <li class="sub-menu-outline">
                <a href={lang === "EN" ? "/" : "/id"}>
                  {lang === "EN" ? "Vendor Registration" : "Pendaftaran Vendor"}
                </a>
              </li>
            </ul>
          </amp-sidebar>
          <amp-sidebar
            id={`side${menuItems[4]?.label}`}
            layout="nodisplay"
            side="right"
          >
            <div
              role="button"
              aria-label="close sidebar"
              on={`tap:side${menuItems[4]?.label}.toggle`}
              tabindex="0"
              class="close-sidebar-left"
            >
              &#x2190;
            </div>
            <ul class="sidebar">
              {menuItems[4] &&
                menuItems[4].childItems.nodes.map((item, i) => (
                  <li key={`investors-menu-mobile-${item.label}`}>
                    <a
                      href={
                        item.url === "/general-information"
                          ? lang === "EN"
                            ? `/investors/`
                            : "/id/investors/"
                          : item.url === "/about-us/board-of-directors"
                          ? `/id${item.url}/`
                          : `/id/investors${item.url}/`
                      }
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
              <li>
                <a
                  href={
                    lang === "EN"
                      ? "/investors/corporate-structure/"
                      : "/id/investors/corporate-structure/"
                  }
                >
                  Corporate Structure
                </a>
              </li>
            </ul>
          </amp-sidebar>
        </>
      ) : (
        <>
          <div className="navbar-toggle-wrapper fixed-top">
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleHandler}
            >
              <Lottie options={mobileToggler} width={30} height={30} />
            </button>
            <Link
              className={`btn btn-primary btn-login ${
                toggleMobile ? "d-none" : isWvc ? "d-none" : ""
              }`}
              to="/login/"
            >
              Login
            </Link>
            {isApartemen && (
              <button
                className="btn btn-primary nav-primary pb-0 pt-1 ml-1"
                id="sound-btn"
                onClick={() => handleSoundClick()}
              >
                <i
                  className={soundToggle.clicked ? "fa fa-pause" : "fa fa-play"}
                  id="play-pause-i"
                ></i>
              </button>
            )}
          </div>
          <nav className={`navbar navbar-nav-main ${navBackground} fixed-top`}>
            <Link className="navbar-brand" to={lang === "ID" ? "/id" : "/"}>
              <img
                className="logo-main"
                src={brandLogoMain}
                alt={brandLogoAlt}
              />
              <img
                className="logo-secondary"
                src={brandLogoSecondary}
                alt={brandLogoAlt}
              />
            </Link>
          </nav>
        </>
      )}

      {/* Mobile Menu*/}
      {!isAmp && (
        <div className={`navbar-collapse ${showMenu} fixed-top`}>
          <div className="container">
            <div className="navbar-collapse-header">
              <form
                className="form-control-search-wrapper"
                action="/search-result"
                target="_top"
              >
                <input
                  type="text"
                  name="key"
                  className="form-control form-control-search"
                  placeholder="Search"
                  id="navMobileSearch"
                />
              </form>
            </div>
            <div className="navbar-collapse-content">
              <ul className="navbar-nav mb-5">
                {menuItemsWPrecious.map((item, i) => {
                  const activeState = urlArr
                    ? urlArr[1] === item.label.toLowerCase()
                      ? "active-path"
                      : ""
                    : ""
                  return i === 0 ? (
                    <li
                      key={i}
                      className={`nav-item ${
                        urlArr ? (urlArr[1] === "" ? "active-path" : "") : ""
                      }`}
                      id={item.label}
                    >
                      <Link
                        className="nav-link"
                        to={`${lang === "EN" ? "" : "/id"}${item.url}`}
                      >
                        {item.label}
                      </Link>
                    </li>
                  ) : item.childItems.nodes.length > 0 ||
                    item.label === "Development" ||
                    item.label === "Pembangunan" ||
                    item.label === "Corpoorate" ||
                    item.label === "Perusahaan" ? (
                    <li
                      key={`mobilemenu-${item.label}`}
                      className={`nav-item nav-mobile ${activeState}`}
                      id={item.label}
                      data-target={item.label}
                    >
                      <a className="nav-link" href="#">
                        {item.label}
                      </a>
                    </li>
                  ) : (
                    <li
                      key={i}
                      className={`nav-item ${activeState}`}
                      id={item.label}
                    >
                      <a
                        className="nav-link"
                        href={`${
                          lang === "EN"
                            ? item.url
                            : item.label.toLowerCase() === "e-catalog" ||
                              item.label.toLowerCase() === "double dream" ||
                              item.label.toLowerCase() === "smart move"
                            ? `${item.url}/`
                            : `/id${item.url}/`
                        }`}
                        target={
                          item.label.toLowerCase() === "e-catalog"
                            ? "_blank"
                            : "_self"
                        }
                      >
                        {item.label}
                      </a>
                    </li>
                  )
                })}
              </ul>
              <div
                className="nav-item nav-desktop d-flex align-items-center"
                id="lang-wrapper"
              >
                <div className="nav-separator">
                  <div className="v-line"></div>
                </div>
                <div className="d-flex lang-switch">
                  <div
                    onClick={setToEN}
                    className={`mr-2 lang-item ${
                      lang === "EN" ? "active" : ""
                    }`}
                  >
                    EN
                  </div>
                  <div className="mr-2">|</div>
                  <div
                    onClick={setToID}
                    className={`lang-item ${lang === "ID" ? "active" : ""}`}
                  >
                    ID
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="navbar-collapse-footer">
            <SocmedLinks
              facebookLink=""
              twitterLink=""
              instagramLink=""
              linkedinLink=""
            />
          </div> */}
          </div>
        </div>
      )}

      {/* Mobile Menu - Development */}
      {!isAmp && (
        <div
          id={menuItems[1].label}
          className="navbar-slide fixed-top"
          data-content
        >
          <div className="navbar-slide-header">
            <div className="container">
              <button
                className="btn btn-link text-dark navBack"
                aria-label="navBack"
              >
                <i className="fa fa-arrow-left"></i>
              </button>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <span className="nav-link p-0 m-0">
                    {lang === "EN" ? "Development" : "Pengembangan"}
                  </span>
                </li>
              </ul>
              <div>
                <a
                  href="https://ecatalog.sinarmasland.com?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_e-Catalog_2021&utm_content=ECatalog_SML_Website_Menu"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-link fx-underline pt-3 pb-0"
                >
                  e-Catalog
                </a>
              </div>
              <Link
                to={lang === "EN" ? "/development/" : "/id/development/"}
                className="btn btn-link"
              >
                {lang === "EN" ? "See All Projects" : "Lihat Semua Proyek"}
              </Link>
            </div>
          </div>
          <Container>
            <ul className="nav-project-mobile list-unstyled mt-4">{items}</ul>
          </Container>
        </div>
      )}

      {/* Mobile Menu - Corporate */}
      {!isAmp && (
        <section
          id={menuItems[2].label}
          className="navbar-slide fixed-top"
          data-content
        >
          <div className="navbar-slide-header">
            <div className="container">
              <button
                className="btn btn-link text-dark navBack"
                aria-label="navBack"
              >
                <i className="fa fa-arrow-left"></i>
              </button>
            </div>
          </div>
          <div className="container">
            <ul className="navbar-nav mb-4">
              <li className="nav-item active">
                <span className="nav-link">
                  {lang === "EN" ? "About" : "Tentang"}
                </span>
                <ul className="navbar-nav mb-3">
                  {menuItems[2].childItems.nodes[0] !== undefined &&
                    menuItems[2].childItems.nodes[0].childItems.nodes.map(
                      (item, i) => {
                        return (
                          <li
                            key={`submenu-mobile-${item.label}`}
                            className="nav-item"
                          >
                            {isAmp ? (
                              <a
                                href={`${
                                  lang === "EN"
                                    ? item.url
                                    : item.label.toLowerCase() ===
                                        "e-catalog" ||
                                      item.label.toLowerCase() ===
                                        "double dream" ||
                                      item.label.toLowerCase() === "smart move"
                                    ? `${item.url}/`
                                    : `/id${item.url}/`
                                }`}
                                className="nav-link"
                              >
                                {item.label}
                              </a>
                            ) : (
                              <Link
                                to={`${
                                  lang === "EN"
                                    ? item.url
                                    : item.label.toLowerCase() ===
                                        "e-catalog" ||
                                      item.label.toLowerCase() ===
                                        "double dream" ||
                                      item.label.toLowerCase() === "smart move"
                                    ? `${item.url}/`
                                    : `/id${item.url}/`
                                }`}
                                className="nav-link"
                              >
                                {item.label}
                              </Link>
                            )}
                          </li>
                        )
                      }
                    )}
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  to={lang === "EN" ? "/partner/" : "/id/partner/"}
                  className="nav-link"
                >
                  {lang === "EN" ? "Partners" : "Partner"}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={lang === "EN" ? "/news/" : "/id/news/"}
                  className="nav-link"
                >
                  {lang === "EN" ? "News" : "Berita"}
                </Link>
              </li>
              <li className="nav-item">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://events.sinarmasland.com/"
                  className="nav-link"
                >
                  {lang === "EN" ? "Events" : "Acara"}
                </a>
              </li>
            </ul>

            <Link
              to={lang === "EN" ? "/career/" : "/id/career/"}
              className="btn btn-outline-dark mb-3"
            >
              <span>{lang === "EN" ? "Career" : "Karir"}</span>
            </Link>
            <Link
              to={lang === "EN" ? "/" : "/id"}
              className="btn btn-outline-dark"
            >
              <span>
                {lang === "EN" ? "Vendor Registration" : "Pendaftaran Vendor"}
              </span>
            </Link>
          </div>
        </section>
      )}

      {/* Mobile Menu - Investors */}
      {!isAmp && (
        <section
          id={menuItems[4]?.label || ""}
          className="navbar-slide fixed-top"
          data-content
        >
          <div className="navbar-slide-header">
            <div className="container">
              <button
                className="btn btn-link text-dark navBack"
                aria-label="navBack"
              >
                <i className="fa fa-arrow-left" />
              </button>
            </div>
          </div>
          <div className="container">
            <ul className="navbar-nav">
              {menuItems[4] &&
                menuItems[4].childItems.nodes.map((item, i) => (
                  <li
                    key={`investors-menu-mobile-${item.label}`}
                    className="nav-item"
                  >
                    <Link
                      className="nav-link"
                      to={
                        item.url === "/general-information"
                          ? lang === "EN"
                            ? `/investors/`
                            : "/id/investors/"
                          : item.url === "/about-us/board-of-directors"
                          ? lang === "EN"
                            ? `${item.url}/`
                            : `/id${item.url}`
                          : item.url === "/living-lab-ventures"
                          ? lang === "EN"
                            ? `${item.url}/`
                            : `/id${item.url}`
                          : lang === "EN"
                          ? `/investors${item.url}/`
                          : `/id/investors${item.url}/`
                      }
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={
                    lang === "EN"
                      ? "/investors/corporate-structure/"
                      : "/id/investors/corporate-structure/"
                  }
                >
                  Corporate Structure
                </Link>
              </li>
            </ul>
          </div>
        </section>
      )}
    </div>
  )
}

export default NavbarMobile
