import React, { useState, useEffect, useLayoutEffect, useContext } from "react"
import { Link, withPrefix, navigate } from "gatsby"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import moment from "moment"

import { LangContext } from "../../context/langContext"

import { replaceUnicode } from "../utils/utils"

import imgRedRibbon from "../../assets/img/common/img_red-ribbon.png"
import iconWaWhite from "../../assets/img/icon-wa-white.png"

const mailchimpUrl = process.env.MAILCHIMP_URL

const Footer = ({ fetchedData, isWfh, location, newsLangSlug, isAmp, isWaForm, waFormClick }) => {
  const [email, setEmail] = useState()
  const { lang, setLang } = useContext(LangContext)
  // const langKey = location.pageContext?.langKey === "id" ? "ID" : "EN"
  const langKey = (location?.pathname?.split('/')[1] || location?.uri?.split('/')[1]) === "id" ? "ID" : lang === "ID" ? "ID" : "EN"
  const menuItems =
    lang === "EN"
      ? fetchedData?.menus?.nodes[0]?.menuItems?.nodes
      : fetchedData?.menus?.nodes[1]?.menuItems?.nodes
  const social = fetchedData?.menus?.nodes[4]?.menuItems?.nodes

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  useLayoutEffect(() => {
    const urlLang = location?.pathname?.split('/')[1] || location?.uri?.split('/')[1]
    if (urlLang === "id") setLang("ID")
    else setLang("EN")
  }, [location])

  useLayoutEffect(() => {
    const newDate = new Date()
    if(!isAmp){
      ; (function loadSalesCandy() {
        var script = document.createElement("script")
        script.src = withPrefix(`sales-candy.js?v=${newDate.getSeconds()}`)
        script.setAttribute("defer", "")
        document.body.appendChild(script)
        console.log("logger-sc => ",script)
      })()
    }
  }, [])

  useEffect(() => {
    if (isWfh) {
      const footerTop = window.document.querySelectorAll(
        ".footer-top"
      )
      const redRibbon = window.document.querySelectorAll(
        ".red-ribbon"
      )
      if (footerTop.length > 0) {
        for (var i = 0; i < footerTop.length; i++) {
          footerTop[i].style.position = "inherit"
        }
      }
      if (redRibbon.length > 0) {
        for (var i = 0; i < redRibbon.length; i++) {
          redRibbon[i].style.zIndex = -1
        }
      }
    }
  }, [])

  const changeLang = (name) => {
    const url = location.pathname?.split('/') || location.uri?.split('/')
    if (newsLangSlug && lang !== name) {
      if (name === "ID") {
        window.location.href = `/id/news/${newsLangSlug}`
      } else if (name === "EN") {
        window.location.href = `/news/${newsLangSlug}`
      }
    } else {
      if (url[1] !== "id" && name === "ID") {
        window.location.replace(`/id${location.pathname ? location.pathname : location.uri}`)
      } else if (url[1] === "id" && name === "EN") {
        url.shift()
        url.shift()
        window.location.replace(`/${url.join('/')}`)
      }
    }
  }

  useEffect(() => { setLang(langKey) }, [langKey])

  const estoreData = [
    { 
      title: "Blibli",
      link: "https://www.blibli.com/merchant/sinar-mas-land-official-store/SIM-70041?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_2021&utm_content=Blibli_Footer_Website_SML"
    },
    { 
      title: "Tokopedia",
      link: "https://www.tokopedia.com/sinarmasland?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_2021&utm_content=Tokopedia_Footer_Website_SML"
    },
  ]

  return (
    <footer>
      <div className="red-ribbon">
        <div className="ribbon red" />
        <div className="ribbon black" />
        {isAmp ? (
          <>
          <amp-img src={imgRedRibbon} class="img-fluid img-mobile-fluid" alt="Red Ribbon" width="183" height="70" ></amp-img>
          <amp-img src={imgRedRibbon} class="img-fluid img-desktop-fluid" alt="Red Ribbon" width="248" height="90" ></amp-img>
          </>
        ) : (
          <>
        <img data-src={imgRedRibbon} className="lazyload img-fluid" alt="Red Ribbon" width={`100`} height={`100`}/>
        <script src={withPrefix("qualtrics.js")} type="text/javascript" defer/>
        </>
        )}
      </div>
      <div className="footer-top py-main">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="footer-col footer-address">
                <h5>Sinar Mas Land Plaza</h5>
                <p className="m-0">Sinar Mas Land Plaza</p>
                {fetchedData.generalSettings?.officeAddress ? 
                  <p>
                    <a href="https://maps.app.goo.gl/KLa5h4Dq1zcoxNsP6" style={{ color: "hsla(0,0%,100%,.75)" }}>{fetchedData.generalSettings.officeAddress}</a> 
                  </p>
                : null}
                {fetchedData.generalSettings?.officePhone ? 
                  <p>
                    <a href={`tel:${fetchedData.generalSettings.officePhone}`} style={{ color: "hsla(0,0%,100%,.75)" }}>
                      {fetchedData.generalSettings.officePhone}
                    </a>
                  </p>
                : null}
              </div>
            </div>
            <div className="col-12 d-lg-none">
              <hr />
            </div>
            <div className="col-6 col-lg-2">
              <div className="footer-col">
                <h5 className="d-none d-lg-block">
                  {lang === "EN" ? "Explore" : "Jelajah"}
                </h5>
                <ul className="footer-nav">
                  {menuItems.map((item, i) => {
                    if (i < 3) {
                      return (
                        <li
                          className="footer-item"
                          key={`footermenu-${item.label}`}
                        >
                          <Link
                            className="footer-link fx-underline"
                            to={
                              lang === "ID"
                                ? item.label.toLowerCase() === "home" 
                                  ? `/id${item.url}` 
                                  : `/id${item.url}/`
                                : item.label.toLowerCase() === "home"
                                  ? item.url
                                  : `${item.url}/`
                            }
                          >
                            {item.label}
                          </Link>
                        </li>
                      )
                    }
                    // return <div key={`empty-${i}`}></div>
                    return <></>
                  })}
                  <li className="footer-item">
                    <a
                      target="_blank"
                      href="https://ecatalog.sinarmasland.com?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_e-Catalog_2021&utm_content=ECatalog_SML_Website_Menu_Footer"
                      className="footer-link fx-underline"
                    >
                      {lang === "EN" ? "e-Catalog" : "e-Katalog"}
                    </a>
                  </li>
                  <li className="footer-item">
                    <a
                      href="/precious/"
                      className="footer-link fx-underline"
                    >
                      Precious
                    </a>
                  </li>
                  <li className="footer-item">
                    <a
                      href="/infiniteliving/"
                      className="footer-link fx-underline"
                    >
                      Infinite Living
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-lg-2">
              <div className="footer-col">
                <h5 className="d-none d-lg-block">&nbsp;</h5>
                <ul className="footer-nav">
                  <li className="footer-item">
                    <Link className="footer-link fx-underline" to={lang === "EN" ? "/career/" : "/id/career/"}>
                      {lang == "EN" ? "Career" : "Karir"}
                    </Link>
                  </li>
                  {menuItems.map((item, i) => {
                    if (i >= 3) {
                      return (
                        <li
                          className="footer-item"
                          key={`footermenu-${item.label}`}
                        >
                          <Link
                            className="footer-link fx-underline"
                            to={`${lang === "ID" ? "/id" : ""}${item.url}/`}
                          >
                            {item.label}
                          </Link>
                        </li>
                      )
                    }
                  })}
                  <li className="footer-item">
                    <Link className="footer-link fx-underline" to={lang === "ID" ? "/id/contact-us/" : "/contact-us/"}>
                      {lang === "EN" ? "Contact Us" : "Hubungi Kami"}
                    </Link>
                  </li>
                  <li className="footer-item">
                    <a
                      className="footer-link fx-underline"
                      rel="noreferrer"
                      target="_blank"
                      href="https://events.sinarmasland.com/"
                    >
                      {lang === "EN" ? "Events" : "Acara"}
                    </a>
                  </li>
                  <li className="footer-item">
                    <a
                      href="https://www.instagram.com/housemopolitan/"
                      className="footer-link fx-underline position-relative"
                    >
                      Housemopolitan
                    </a>
                  </li>
                  {/* <li className="footer-item">
                    <a
                      href="/bca-expo/"
                      className="footer-link fx-underline position-relative"
                    >
                      <div class="bg-primary text-white" style={{
                        position:"absolute",
                        right:"-10px",
                        top:"3px",
                        fontSize:"6px",
                        borderRadius:"9px",
                        paddingLeft:"5px",
                        paddingRight:"5px",
                        textAlign:"center"
                      }}>NEW</div>
                      BCA Expo 2024
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-12 d-lg-none">
              <hr />
            </div>
            <div className="col-12 col-lg-2">
              <div className="footer-col">
                <h5>{langKey === "EN" ? "Our e-Store" : "e-Store Kami"}</h5>
                <ul className="footer-nav">
                  {estoreData.map((item, i) => {
                    return(
                      <li className="footer-item" key={i}>
                        <Link className="footer-link fx-underline" to={item.link} target="_blank">
                          {item.title}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
            <div className="col-12 d-lg-none">
              <hr />
            </div>
            <div className="col-lg-2">
              <div className="row">
                <div className="col-xs-8 col-sm-8 col-md-12">
                  <div className="footer-col footer-newsletter">
                    <h5>{langKey === "ID" ? "Ikuti Kami" : "Follow Us On"}</h5>
                    <ul className="social-nav">
                      {social &&
                        social.map((item, i) => {
                          switch (item.label.toLowerCase()) {
                            case "facebook":
                              return (
                                isAmp ? (
                                  <li
                                    key={`social-media-${i}`}
                                    className="social-item"
                                  >
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      key={`footer-socmed-${i}`}
                                      className="social-link footer-facebook-icon"
                                    >                                  
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"/>
                                      </svg>
                                    </a>
                                  </li>
                                ):(
                                  <li
                                    key={`social-media-${i}`}
                                    className="social-item"
                                  >
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      key={`footer-socmed-${i}`}
                                      className="social-link gtm-fb"
                                      aria-label="Follow us on facebook"
                                    >
                                      <i className="fab fa-facebook-square footer-social-icon"></i>
                                    </a>
                                  </li>
                                )
                              )
                            case "twitter":
                              return (
                                isAmp ? (
                                  <li
                                    key={`social-media-${i}`}
                                    className="social-item"
                                  >
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      key={`footer-socmed-${i}`}
                                      className="social-link footer-twitter-icon"
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
                                      </svg>
                                    </a>
                                  </li>
                                ):(
                                  <li
                                    key={`social-media-${i}`}
                                    className="social-item"
                                  >
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      key={`footer-socmed-${i}`}
                                      className="social-link footer-twitter-icon gtm-twitter"
                                      aria-label="Follow us on twitter"
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
                                      </svg>
                                    </a>
                                  </li>
                                )
                              )
                            case "instagram":
                              return (
                                isAmp ? (
                                  <li
                                    key={`social-media-${i}`}
                                    className="social-item"
                                  >
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      key={`footer-socmed-${i}`}
                                      className="social-link footer-instagram-icon"
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                                      </svg>
                                    </a>
                                  </li>
                                ):(
                                  <li
                                    key={`social-media-${i}`}
                                    className="social-item"
                                  >
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      key={`footer-socmed-${i}`}
                                      className="social-link gtm-ig"
                                      aria-label="Follow us on instagram"
                                    >
                                      <i className="fab fa-instagram footer-social-icon"></i>
                                    </a>
                                  </li>
                                )
                              )
                            case "linkedin":
                              return (
                                isAmp ? (
                                  <li
                                    key={`social-media-${i}`}
                                    className="social-item"
                                  >
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      key={`footer-socmed-${i}`}
                                      className="social-link footer-linkedin-icon"
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
                                      </svg>
                                    </a>
                                  </li>
                                ):(
                                  <li
                                    key={`social-media-${i}`}
                                    className="social-item"
                                  >
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      key={`footer-socmed-${i}`}
                                      className="social-link"
                                      aria-label="Follow us on Linkedin"
                                    >
                                      <i className="fab fa-linkedin footer-social-icon"></i>
                                    </a>
                                  </li>
                                )
                              )
                            case "youtube":
                              return (
                                isAmp ? (
                                  <li
                                    key={`social-media-${i}`}
                                    className="social-item"
                                  >
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      key={`footer-socmed-${i}`}
                                      className="social-link footer-youtube-icon"
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                        <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/>
                                      </svg>
                                    </a>
                                  </li>
                                ):(
                                  <li
                                    key={`social-media-${i}`}
                                    className="social-item"
                                  >
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      key={`footer-socmed-${i}`}
                                      className="social-link gtm-youtube"
                                      aria-label="Follow us on youtube"
                                    >
                                      <i className="fab fa-youtube footer-social-icon"></i>
                                    </a>
                                  </li>
                                )
                              )
                            case "tiktok":
                              return (
                                <li
                                  key={`social-media-${i}`}
                                  className="social-item"
                                >
                                  <a
                                    href={item.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={`footer-socmed-${i}`}
                                    className="social-link footer-tiktok-icon"
                                    aria-label="Follow us on Tiktok"
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/>
                                    </svg>
                                  </a>
                                </li>
                              )
                            default:
                              return <div key={`empty-social-${i}`}></div>
                          }
                        })}
                    </ul>
                  </div>
                </div>
                <div className="col-xs-4 col-sm-4 col-md-12 footer-scroll-mob">
                  <button className="btn btn-scrolltop" aria-label="Back to Top" onClick={() => scrollToTop()}><i class="fa fa-arrow-up"></i></button>
                </div>
              </div>
              {/* SWITCH LANGUAGE */}
              {/* <div className="footer-col footer-newsletter pt-6">
                <div className="d-flex">
                  <p className={`switch-lang mr-2 ${langKey === "EN" ? "font-weight-bold" : ""}`}
                    onClick={() => changeLang("EN")}
                  >EN</p>
                  <p className={`switch-lang ${langKey === "ID" ? "font-weight-bold" : ""}`}
                    onClick={() => changeLang("ID")}
                  >ID</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {isWaForm && (
        <div className="whatsapp-wrapper">
          <button 
            className="btn btn-primary-whatsapp"
            onClick={waFormClick}>
            <img src={iconWaWhite} alt="Whatsapp" width="22" height="22" />
            {/* <i className="fab fa-whatsapp"></i>&nbsp;&nbsp;&nbsp;Whatsapp */}
          </button>
        </div>
      )}
      <div className="footer-scroll container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <button className="btn btn-scrolltop" aria-label="Back to Top" onClick={() => scrollToTop()}><i class="fa fa-arrow-up"></i></button>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <span className="footer-bottom-text">
                © {moment().year()} <strong> Sinar Mas Land </strong>{" "}
                {lang === "EN"
                  ? "All Right Reserved"
                  : "Hak cipta dilindungi Undang-undang"}
              </span>
            </div>
            <div className="col-lg-6">
              <span className="footer-bottom-link">
                <Link to="/privacy-policy">
                  {replaceUnicode(fetchedData.privacyPolicy.title)}
                </Link>
                <span className="footer-bottom-divider">•</span>
                <Link to="/terms-&-conditions">
                  {replaceUnicode(fetchedData.termAndConditions.title)}
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
